import React, { useState } from 'react';
import { RiArrowDropDownLine } from "react-icons/ri";
import { RiArrowDropUpLine } from "react-icons/ri";

function Faq() {
  const [openFaq, setOpenFaq] = useState(null);
  const toggleFaq = (index) => {
    setOpenFaq(openFaq === index ? null : index);
  };
  const faqs = [
    {
      question: "What services does Travoolah offer?",
      answer: (
        <p>
          At <strong>Travoolah</strong>, we specialize in providing tailor-made travel experiences in Kashmir. Our services include:
          <br />
          <ul className="list-disc pl-5">
            <li>Customized tour packages (family, honeymoon, adventure, and group tours)</li>
            <li>Hotel bookings in top-rated properties</li>
            <li>Transportation services (car rentals, guided tours)</li>
            <li>Trekking and adventure activities</li>
            <li>Local sightseeing tours</li>
            <li>Cultural and heritage tours</li>
            <li>Airport transfers and logistics</li>
          </ul>
        </p>
      ),
    },
    {
      question: "How can I book a tour with Travoolah?",
      answer: (
        <p>
          You can book your tour through:
          <ul className="list-disc pl-5">
            <li>Our website (online booking form)</li>
            <li>Calling our customer service team</li>
            <li>Sending us an email with your travel requirements</li>
          </ul>
          We recommend reaching out in advance for customized itineraries or last-minute bookings.
        </p>
      ),
    },
    {
      question: "What is the best time to visit Kashmir?",
      answer: (
        <p>
          The best time to visit depends on your preferences:
          <ul className="list-disc pl-5">
            <li><strong>Summer (April to June):</strong> Ideal for sightseeing, trekking, and enjoying the gardens and lakes.</li>
            <li><strong>Autumn (September to November):</strong> Perfect for witnessing fall foliage and serene landscapes.</li>
            <li><strong>Winter (December to February):</strong> Best for winter sports, snow activities, and snow-capped vistas.</li>
          </ul>
        </p>
      ),
    },
    {
      question: "Is it safe to travel to Kashmir?",
      answer: (
        <p>
          Yes, Kashmir is a safe and welcoming destination for travelers. We ensure the highest safety standards, guided by local experts. However, we recommend keeping up with local travel advisories and regulations.
        </p>
      ),
    },
    {
      question: "What is included in your Kashmir tour packages?",
      answer: (
        <p>
          Our tour packages typically include:
          <ul className="list-disc pl-5">
            <li>Comfortable accommodation</li>
            <li>Meals as per the package (breakfast, lunch, or dinner)</li>
            <li>Sightseeing tours with a knowledgeable guide</li>
            <li>Transportation (private or group transport)</li>
            <li>Entry fees to popular spots</li>
            <li>Airport transfers (if included in the package)</li>
          </ul>
          Specific inclusions depend on the chosen tour package.
        </p>
      ),
    },
    {
      question: "Can I customize my Kashmir tour package?",
      answer: (
        <p>
          Absolutely! We specialize in creating customized tours based on your preferences. Whether it’s a family trip, honeymoon, or adventure, we’ll tailor a package to suit your needs.
        </p>
      ),
    },
    {
      question: "Do I need a visa to visit Kashmir?",
      answer: (
        <p>
          For foreign tourists, a valid visa to India is required. Kashmir, being part of India, follows the same visa rules. Indian citizens do not require a visa.
        </p>
      ),
    },
    {
      question: "What should I pack for a trip to Kashmir?",
      answer: (
        <p>
          <ul className="list-disc pl-5">
            <li><strong>Summer:</strong> Light clothing, sunscreen, sunglasses, and comfortable shoes.</li>
            <li><strong>Winter:</strong> Warm clothes, woolens, gloves, hats, and thermal wear for snow activities.</li>
          </ul>
          Additionally, carry a camera, power bank, personal medication, and toiletries.
        </p>
      ),
    },
    {
      question: "What payment methods do you accept?",
      answer: (
        <p>
          We accept:
          <ul className="list-disc pl-5">
            <li>Credit and debit cards</li>
            <li>Bank transfers</li>
            <li>Online payment gateways (PayPal, Google Pay, etc.)</li>
          </ul>
          A receipt will be provided upon successful payment.
        </p>
      ),
    },
    {
      question: "Can I cancel or modify my booking?",
      answer: (
        <p>
          Yes, cancellations and modifications are possible but subject to terms and conditions. Contact us as soon as possible for changes. Cancellation fees may apply based on the timing of your request.
        </p>
      ),
    },
    {
      question: "Do you offer travel insurance?",
      answer: (
        <p>
          While we don’t directly offer travel insurance, we recommend purchasing it for added peace of mind. We can assist you in finding a reliable provider.
        </p>
      ),
    },
    {
      question: "How do I contact Travoolah?",
      answer: (
        <p>
          You can contact us via:
          <ul className="list-none">
            <li><strong>Phone:</strong> +91 987 654 3210</li>
            <li><strong>Email:</strong> support@travoolah.com</li>
            <li><strong>Office:</strong> [Insert office address]</li>
          </ul>
        </p>
      ),
    },
  ];
  return (
    <div className="py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-4xl mx-auto">
        <h1 className="text-3xl font-bold text-gray-900 mb-6 mt-10 text-center">Frequently Asked Questions</h1>
        {faqs.map((faq, index) => (
          <div key={index} className="mb-4">
            <div
              className={`z-50 border-t-2 border-l-2 border-r-2 ${
                openFaq !== index ? "border-b-2 rounded-b-md" : "border-b-0"
              } py-2 rounded-t-md flex items-center justify-between px-5 w-11/12 mx-auto bg-[#F26222]/20`}
            >
              <h2 className="text-gray-800 font-medium">{faq.question}</h2>
              <button
                onClick={() => toggleFaq(index)}
                className="transition-all duration-300 ease-in-out"
              >
                {openFaq === index ? <RiArrowDropUpLine /> : <RiArrowDropDownLine />}
              </button>
            </div>
            {openFaq === index && (
              <div className="z-10 px-5 py-4 w-11/12 mx-auto bg-[#F26222]/20 rounded-b-md border-b-2 border-l-2 border-r-2">
                {faq.answer}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}

export default Faq;
