import React, { useEffect, useState } from 'react';
import axios from "axios";
import { Plus, Trash2 } from 'lucide-react';

function DayWiseItinerariesForm() {
  const [eventManagements, setEventManagements] = useState([]);
  const [selectedEventId, setSelectedEventId] = useState('');
  const [itineraries, setItineraries] = useState([]);
  const [newItinerary, setNewItinerary] = useState({
    dayNumber: '',
    title: '',
    details: ''
  });

  useEffect(() => {
    fetchEventManagements();
  }, []);

  useEffect(() => {
    if (selectedEventId) {
      fetchItineraries();
    } else {
      setItineraries([]);
    }
  }, [selectedEventId]);

  const fetchEventManagements = async () => {
    try {
      const response = await axios.get('https://api.travoolah.com/api/EventManagement');
      setEventManagements(response.data);
    } catch (error) {
      console.error("Error fetching event management packages:", error);
    }
  };

  const fetchItineraries = async () => {
    try {
      const response = await axios.get(`https://api.travoolah.com/api/EventmanagementDayWiseItineraries?eventManagementId=${selectedEventId}`);
      setItineraries(response.data);
    } catch (error) {
      console.error("Error fetching itineraries:", error);
    }
  };

  const handleAddItinerary = async () => {
    if (!selectedEventId) {
      alert("Please select an event management package first.");
      return;
    }
    const payload = {
      dayNumber: parseInt(newItinerary.dayNumber),
      title: newItinerary.title,
      details: newItinerary.details,
      eventManagementId: parseInt(selectedEventId)
    };
    try {
      const response = await axios.post('https://api.travoolah.com/api/EventmanagementDayWiseItineraries', payload);
      setItineraries([...itineraries, response.data]);
      setNewItinerary({ dayNumber: '', title: '', details: '' });
    } catch (error) {
      console.error("Error adding itinerary:", error);
    }
  };

  const handleDeleteItinerary = async (id) => {
    if (window.confirm("Are you sure you want to delete this itinerary?")) {
      try {
        await axios.delete(`https://api.travoolah.com/api/EventmanagementDayWiseItineraries/${id}`);
        setItineraries(itineraries.filter(it => it.id !== id));
      } catch (error) {
        console.error("Error deleting itinerary:", error);
      }
    }
  };

  const handleEventSelect = (e) => {
    setSelectedEventId(e.target.value);
  };

  return (
    <div className="max-w-3xl mx-auto p-6 bg-white shadow-lg rounded-lg">
      <h2 className="text-2xl font-bold mb-6 text-gray-800">Manage Day Wise Itineraries</h2>

      {/* Event Selection Dropdown */}
      <div className="mb-6">
        <label htmlFor="eventManagementId" className="block text-gray-700 font-medium mb-2">
          Select Event Management Package:
        </label>
        <select
          id="eventManagementId"
          value={selectedEventId}
          onChange={handleEventSelect}
          className="w-full p-2 border border-gray-300 rounded-md"
        >
          <option value="">-- Select an Event Management Package --</option>
          {eventManagements.map(event => (
            <option key={event.id} value={event.id}>
              {event.title}
            </option>
          ))}
        </select>
      </div>

      {selectedEventId && (
        <>
          <div className="mb-6">
            <h3 className="text-lg font-semibold mb-3">Add New Itinerary</h3>
            <input
              type="number"
              value={newItinerary.dayNumber}
              onChange={(e) => setNewItinerary({ ...newItinerary, dayNumber: e.target.value })}
              placeholder="Day Number"
              className="w-full p-2 border border-gray-300 rounded-md mb-2"
            />
            <input
              type="text"
              value={newItinerary.title}
              onChange={(e) => setNewItinerary({ ...newItinerary, title: e.target.value })}
              placeholder="Title"
              className="w-full p-2 border border-gray-300 rounded-md mb-2"
            />
            <textarea
              value={newItinerary.details}
              onChange={(e) => setNewItinerary({ ...newItinerary, details: e.target.value })}
              placeholder="Details"
              className="w-full p-2 border border-gray-300 rounded-md mb-2"
            />
            <button
              onClick={handleAddItinerary}
              className="p-2 bg-green-500 text-white rounded-md hover:bg-green-600 flex items-center"
            >
              <Plus size={20} className="mr-1" />
              Add Itinerary
            </button>
          </div>
          <h3 className="text-lg font-semibold mb-3">Existing Itineraries</h3>
          <ul className="divide-y divide-gray-200">
            {itineraries.map((itinerary) => (
              <li key={itinerary.id} className="py-4 flex justify-between items-center">
                <div>
                  <p className="font-medium">Day {itinerary.dayNumber}: {itinerary.title}</p>
                  <p className="text-sm text-gray-600">{itinerary.details}</p>
                </div>
                <button
                  onClick={() => handleDeleteItinerary(itinerary.id)}
                  className="bg-red-500 text-white rounded-md p-2 hover:bg-red-600 transition duration-300"
                >
                  <Trash2 size={18} />
                </button>
              </li>
            ))}
          </ul>
        </>
      )}
    </div>
  );
}

export default DayWiseItinerariesForm;
