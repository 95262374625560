import React, { useState, useEffect } from "react";
import axios from "axios";
import { PlusCircle, Edit2, Trash2 } from "lucide-react";

function ServicesAdmin() {
  const [services, setServices] = useState([]);
  const [hotels, setHotels] = useState([]);
  const [selectedHotel, setSelectedHotel] = useState("");
  const [serviceForm, setServiceForm] = useState({
    id: null,
    title: "",
    description: "",
    icon: "",
  });

  // Fetch hotels
  const fetchHotels = async () => {
    try {
      const response = await axios.get("https://api.travoolah.com/api/Hotel");
      setHotels(response.data);
    } catch (error) {
      console.error("Error fetching hotels:", error);
    }
  };

  // Fetch services for the selected hotel
  const fetchServices = async () => {
    if (!selectedHotel) {
      setServices([]);
      return;
    }
    try {
      const response = await axios.get(
        `https://api.travoolah.com/api/Services?hotelId=${selectedHotel}`
      );
      setServices(response.data);
    } catch (error) {
      console.error("Error fetching services:", error);
    }
  };

  useEffect(() => {
    fetchHotels();
  }, []);

  useEffect(() => {
    fetchServices();
  }, [selectedHotel]);

  // Handle form input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setServiceForm((prev) => ({ ...prev, [name]: value }));
  };

  // Handle hotel selection
  const handleHotelChange = (e) => {
    setSelectedHotel(e.target.value);
    setServiceForm({ id: null, title: "", description: "", icon: "" }); // Reset form
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!selectedHotel) {
      alert("Please select a hotel first.");
      return;
    }
    try {
      const payload = { ...serviceForm, hotelId: selectedHotel };
      if (serviceForm.id) {
        await axios.put(
          `https://api.travoolah.com/api/Services/${serviceForm.id}`,
          payload,
          { 
            headers: { 
                'Content-Type': 'application/json',
            }
        }
        );
      } else {
        await axios.post("https://api.travoolah.com/api/Services", payload, 
          { 
            headers: { 
              'Content-Type': 'application/json',
            }
        }
        );
      }
      resetForm();
      fetchServices();
    } catch (error) {
      console.error("Error submitting service:", error);
    }
  };

  // Edit service
  const handleEdit = (service) => {
    setServiceForm({
      id: service.id,
      title: service.title,
      description: service.description,
      icon: service.icon,
    });
  };

  // Delete service
  const handleDelete = async (id) => {
    if (window.confirm("Are you sure you want to delete this service?")) {
      try {
        await axios.delete(`https://api.travoolah.com/api/Services/${id}`);
        fetchServices();
      } catch (error) {
        console.error("Error deleting service:", error);
      }
    }
  };

  // Reset form
  const resetForm = () => {
    setServiceForm({
      id: null,
      title: "",
      description: "",
      icon: "",
    });
  };

  return (
    <div className="max-w-4xl mx-auto p-6 bg-white shadow-lg rounded-lg">
      <h2 className="text-2xl font-bold mb-6 text-gray-800">Manage Services</h2>

      {/* Hotel Dropdown */}
      <div className="mb-6">
        <label htmlFor="hotel-select" className="block text-gray-700 font-medium mb-2">
          Select Hotel
        </label>
        <select
          id="hotel-select"
          value={selectedHotel}
          onChange={handleHotelChange}
          className="w-full p-2 border border-gray-300 rounded-md"
        >
          <option value="">-- Select a Hotel --</option>
          {hotels.map((hotel) => (
            <option key={hotel.id} value={hotel.id}>
              {hotel.title}
            </option>
          ))}
        </select>
      </div>

      {/* Service Form */}
      <form onSubmit={handleSubmit} className="space-y-4">
        <input
          type="text"
          name="title"
          value={serviceForm.title}
          onChange={handleChange}
          placeholder="Service Title"
          required
          className="w-full p-2 border border-gray-300 rounded-md"
        />
        <textarea
          name="description"
          value={serviceForm.description}
          onChange={handleChange}
          placeholder="Service Description"
          className="w-full p-2 border border-gray-300 rounded-md h-24"
        />
        <input
          type="text"
          name="icon"
          value={serviceForm.icon}
          onChange={handleChange}
          placeholder="Service Icon URL"
          className="w-full p-2 border border-gray-300 rounded-md"
        />
        <button
          type="submit"
          className="w-full bg-blue-600 text-white py-2 px-4 rounded-md hover:bg-blue-700 transition duration-300 flex items-center justify-center"
        >
          <PlusCircle className="mr-2" size={20} />
          {serviceForm.id ? "Update Service" : "Add Service"}
        </button>
      </form>

      {/* Services List */}
      <h3 className="text-xl font-semibold mt-8 mb-4 text-gray-800">Existing Services</h3>
      {selectedHotel ? (
        <ul className="divide-y divide-gray-200">
          {services.length > 0 ? (
            services.map((service) => (
              <li key={service.id} className="py-4 flex justify-between items-center">
                <div>
                  <h4 className="text-lg font-semibold">{service.title}</h4>
                  <p className="text-sm text-gray-600">{service.description}</p>
                </div>
                <div>
                  <button
                    onClick={() => handleEdit(service)}
                    className="bg-yellow-500 text-white rounded-md p-2 mr-2 hover:bg-yellow-600 transition duration-300"
                  >
                    <Edit2 size={18} />
                  </button>
                  <button
                    onClick={() => handleDelete(service.id)}
                    className="bg-red-500 text-white rounded-md p-2 hover:bg-red-600 transition duration-300"
                  >
                    <Trash2 size={18} />
                  </button>
                </div>
              </li>
            ))
          ) : (
            <p className="text-gray-500">No services found for this hotel.</p>
          )}
        </ul>
      ) : (
        <p className="text-gray-500">Please select a hotel to view its services.</p>
      )}
    </div>
  );
}

export default ServicesAdmin;
