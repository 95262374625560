import React from 'react';
import { Routes, Route, Navigate, Outlet } from 'react-router-dom';
import AdminDashboardWrapper from './AdminDashboardWrapper';
import TravelPackageForm from './TravelPagckageForm';
import TransportationForm from './TransportationForm';
import PhotographForm from './PhotographForm';
import EventManagementForm from './EventManagementForm';
import AdventureForm from './AdventureForm';
import Wellness from './Wellness';
import HotelsAdmin from './HotelsAdmin';
import VillasAdmin from './VillasAdmin';
// import ContactForm from '../HotelContact';
// import HotelDetailsPage from '../HotelDetailsPage';
// import AdventureDetailsPage from '../AdventureDetailsPage';
// import ContactFormAdventure from '../Contact';
import TravelItenaries from './TravelItenaries';
import AddonsForm from './AddonsForm';
import DayWiseItinerariesForm from './DayWiseItinerariesForm';
import PackageInclude from './PackageInclude';
import ServicesAdmin from './ServiceAdmin';
import TravelAi from './TravelAi';


function AdminRoutes() {
  const token = localStorage.getItem('jwtToken');

  if (!token) {
    return <Navigate to="/admin/login" replace />;
  }

  return (
    <Routes>
      <Route path="/" element={<AdminDashboardWrapper />}>
        <Route index element={<Navigate to="dashboard" replace />} />
        <Route path="dashboard" element={<Outlet />} />
        <Route path='hotels' element={<HotelsAdmin />} />
        <Route path='hotelservices' element={<ServicesAdmin />} />
        <Route path='villas' element={<VillasAdmin />} />
        <Route path="travel" element={<TravelPackageForm />} />
        <Route path="transport" element={<TransportationForm />} />
        <Route path="shoots" element={<PhotographForm />} />
        <Route path="event" element={<EventManagementForm />} />
        <Route path="adventure" element={<AdventureForm />} />
        <Route path="wellness" element={<Wellness />} />
        <Route path='itenaries' element={<TravelItenaries />} />
        <Route path='addons' element={<AddonsForm />} />
        <Route path='eventDayWize' element={<DayWiseItinerariesForm />} />
        <Route path='packageInclude' element={<PackageInclude/>} />
        <Route path='travelAi' element={<TravelAi />} />
        {/* <Route path="/hotel/:id" element={<HotelDetailsPage />} /> */}
        {/* <Route path="/contact-form/:id" element={<ContactForm />} /> */}

        {/* <Route path="/adventure/:id" element={<AdventureDetailsPage />} /> */}
        {/* <Route path="/contact-form-adventure/:id" element={<ContactFormAdventure />} /> */}
        
        
      </Route>
      <Route path="*" element={<Navigate to="/admin/dashboard" replace />} />
    </Routes>
  );
}

export default AdminRoutes;