import React, {useState , useEffect} from 'react'
import Card from '../Common/Card'
import axios from 'axios';
import { useNavigate } from "react-router-dom";

function LuxuryTravel() {
  const [customTravel , setCustomTravelState] = useState([]);

  // Define an async function to fetch data
  const getData = async () => {
    try {
      const response = await axios.get("https://api.travoolah.com/api/TravelPackage");
      // Assuming the data is an array, set it directly
      // console.log("resp" , response);
      setCustomTravelState(response.data);
    } catch (error) {
      console.error("Error fetching adventure data:", error);
    }
  };
  
    useEffect(() => {
      getData();
    }, []);

    const navigate = useNavigate();

    const handleCardClick = (id) => {
        navigate(`/travel-package/${id}`)
    }


  return (
    <div className='flex flex-col py-5'>
      <div className='text-center'> 
        <h3 className='text-[#353535] text-2xl md:text-3xl font-semibold px-5 py-5'>Luxury and VIP Travel Packages</h3>
      </div>
      <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 px-5 py-5'>
        {/* <Card img={Lalit} title={"The Lalit Grand Palace"} address={"Gupkar Rd, Srinagar, J&K 190001"} oldPrice={"7999"} newPrice={"4999/Night"}/> */}
        {customTravel.length > 0 ? customTravel
              .filter((event) => event.travelPackageCategory?.name === "LuxuryAndVIP") // Filter by category name
              .map((event, index) => (
                <div key={index} className='flex-none w-[280px] sm:w-[320px] snap-start'>
                  <Card 
                img={event.images && event.images.length > 0 ? `https://api.travoolah.com/${event.images[0]}` : '/placeholder-image.jpg'} // Fallback to placeholder
                title={event.title} 
                    duration={event.duration}
                    oldPrice={`${event.price + 1500}`}
                    newPrice={event.price} 
                    address={event.description}
                    onClick={()=>{
                      handleCardClick(event.id)
                    }}
                  />
                </div>
              )):  <p>No data avalible</p>}
      </div>
    </div>
  )
}

export default LuxuryTravel