// import React, { useState, useEffect } from 'react';
// import { Link } from 'react-router-dom';
// import logo from '../../Assets/logo.svg';
// import cabs from '../../Assets/taxi.svg';
// import travel from '../../Assets/travelPackages.svg';
// import shoots from '../../Assets/shoots.svg';
// import EventManagement from '../../Assets/eventManagement.svg';
// import Adventure from "../../Assets/adventure.svg";
// import Wellness from "../../Assets/wellness.svg";
// import Hotels from "../../Assets/hotels.svg";
// import Villas from "../../Assets/villas.svg";
// import dots from "../../Assets/threeDots.svg";

// const Navbar = () => {
//   const [isOpen, setIsOpen] = useState(false);
//   const [selected, setSelected] = useState('');
//   const [isLoggedIn, setIsLoggedIn] = useState(false);
//   const [dropdownOpen, setDropdownOpen] = useState(false);

//   useEffect(() => {
//     const token = localStorage.getItem('jwtToken');
//     setIsLoggedIn(!!token);
//   }, []);

//   const handleSelect = (item) => {
//     setSelected(item);
//     setIsOpen(false);
//   };

//   const handleLogout = () => {
//     localStorage.removeItem('jwtToken');
//     setIsLoggedIn(false);
//     window.location.href = '/admin/login';
//   };

//   const navItems = [
//     { name: 'Hotels', icon: Hotels, link: '/hotels' },
//     { name: 'Villas', icon: Villas, link: '/villas' },
//     { name: 'Travel Packages', icon: travel, link: '/travel-packages' },
//     { name: 'Transportation', icon: cabs, link: '/transportation' },
//     { name: 'Adventure', icon: Adventure, link: '/adventure' },
//     {
//       name: "Others", icon: dots, link: '',
//       subItems: [
//         { name: 'Photography & Videography', icon: shoots, link: '/photoShoots' },
//         { name: 'Event Management', icon: EventManagement, link: '/eventManagement' },
//         { name: 'Wellness and Relaxation', icon: Wellness, link: '/wellness' },
//       ],
//     },
//   ];

//   const adminNavItems = [
//     { name: 'Dashboard', link: '/admin/dashboard' },
//     { name: 'Hotels', icon: Hotels, link: '/hotels' },
//     { name: 'Villas', icon: Villas, link: '/villas' },
//     { name: 'Travel Packages', icon: travel, link: '/travel-packages' },
//     { name: 'Transportation', icon: cabs, link: '/transportation' },
//     { name: 'Adventure', icon: Adventure, link: '/adventure' },
//     {
//       name: "Others", icon: dots, link: '',
//       subItems: [
//         { name: 'Photography & Videography', icon: shoots, link: '/photoShoots' },
//         { name: 'Event Management', icon: EventManagement, link: '/eventManagement' },
//         { name: 'Wellness and Relaxation', icon: Wellness, link: '/wellness' },
//       ],
//     },
//   ];

//   return (
//     <nav className="bg-white fixed w-full z-50 top-0 left-0 right-0 drop-shadow-lg">
//       <div className="max-w-screen-2xl flex flex-wrap items-center justify-between mx-auto py-3 px-5">
//         <Link to="/" className="flex items-center" onClick={() => { handleSelect(''); window.scrollTo(0, 0); }}>
//           <img src={logo} className="h-12 w-40 object-cover" alt="Logo" />
//         </Link>
//         <button
//           onClick={() => setIsOpen(!isOpen)}
//           type="button"
//           className="inline-flex items-center p-2 ml-3 text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200"
//         >
//           <span className="sr-only">Open main menu</span>
//           <svg className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
//             <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16m-7 6h7" />
//           </svg>
//         </button>
//         <div className={`${isOpen ? 'block' : 'hidden'} w-full md:block md:w-auto`} id="navbar-default">
//           <ul className="font-medium flex flex-col md:flex-row md:space-x-8 mt-4 md:mt-0">
//             {(isLoggedIn ? adminNavItems : navItems).map((item) => (
//               <li key={item.name} className="relative flex flex-col transition duration-300 cursor-pointer items-center group">
//                 <Link
//                   to={item.link}
//                   className={`flex items-center gap-x-2 py-2 pl-3 pr-4 ${selected === item.name.toLowerCase() ? 'text-[#F26222]' : 'text-gray-900'} md:p-0`}
//                   onClick={() => { handleSelect(item.name.toLowerCase()); window.scrollTo(0, 0); setIsOpen(false); }}
//                 >
//                   {item.icon && <img src={item.icon} alt={item.name} className="h-5 w-5" />}
//                   <span className="text-base">{item.name}</span>
//                 </Link>
//                 <div className={`w-full h-1 rounded-lg bg-[#F26222] mt-2 transition-opacity duration-300 ${selected === item.name.toLowerCase() ? 'opacity-100' : 'opacity-0'}`}></div>
//               </li>
//             ))}
//           </ul>
//         </div>
//         {isLoggedIn && (
//           <button onClick={handleLogout} className="p-3 bg-red-500 text-white rounded hover:bg-red-600">
//             Logout
//           </button>
//         )}
//       </div>
//     </nav>
//   );
// };

// export default Navbar;



// import React, { useState, useEffect } from 'react';
// import { Link } from 'react-router-dom';
// import logo from '../../Assets/logo.svg';
// import cabs from '../../Assets/taxi.svg';
// import travel from '../../Assets/travelPackages.svg';
// import shoots from '../../Assets/shoots.svg';
// import EventManagement from '../../Assets/eventManagement.svg';
// import Adventure from "../../Assets/adventure.svg";
// import Wellness from "../../Assets/wellness.svg";
// import Hotels from "../../Assets/hotels.svg";
// import Villas from "../../Assets/villas.svg";
// import dots from "../../Assets/threeDots.svg";

// const Navbar = () => {
//   const [isOpen, setIsOpen] = useState(false);
//   const [selected, setSelected] = useState('');
//   const [isLoggedIn, setIsLoggedIn] = useState(false);
//   const [dropdownOpen, setDropdownOpen] = useState(false);

//   useEffect(() => {
//     const token = localStorage.getItem('jwtToken');
//     setIsLoggedIn(!!token);
//   }, []);

//   const handleSelect = (item) => {
//     setSelected(item);
//     setIsOpen(false);
//   };

//   const handleLogout = () => {
//     localStorage.removeItem('jwtToken');
//     setIsLoggedIn(false);
//     window.location.href = '/admin/login';
//   };

//   const navItems = [
//     { name: 'Hotels', icon: Hotels, link: '/hotels' },
//     { name: 'Villas', icon: Villas, link: '/villas' },
//     { name: 'Travel Packages', icon: travel, link: '/travel-packages' },
//     { name: 'Transportation', icon: cabs, link: '/transportation' },
//     { name: 'Adventure', icon: Adventure, link: '/adventure' },
//     {
//       name: "Others", icon: dots, link: '',
//       subItems: [
//         { name: 'Photography & Videography', icon: shoots, link: '/photoShoots' },
//         { name: 'Event Management', icon: EventManagement, link: '/eventManagement' },
//         { name: 'Wellness and Relaxation', icon: Wellness, link: '/wellness' },
//       ],
//     },
//   ];

//   const adminNavItems = [
//     { name: 'Dashboard', link: '/admin/dashboard' },
//     { name: 'Hotels', icon: Hotels, link: '/hotels' },
//     { name: 'Villas', icon: Villas, link: '/villas' },
//     { name: 'Travel Packages', icon: travel, link: '/travel-packages' },
//     { name: 'Transportation', icon: cabs, link: '/transportation' },
//     { name: 'Adventure', icon: Adventure, link: '/adventure' },
//     {
//       name: "Others", icon: dots, link: '',
//       subItems: [
//         { name: 'Photography & Videography', icon: shoots, link: '/photoShoots' },
//         { name: 'Event Management', icon: EventManagement, link: '/eventManagement' },
//         { name: 'Wellness and Relaxation', icon: Wellness, link: '/wellness' },
//       ],
//     },
//   ];

//   return (
//     <nav className='bg-white fixed w-full z-50 top-0 left-0 right-0 drop-shadow-lg'>
//       <div className="max-w-screen-2xl flex flex-wrap items-center justify-between mx-auto py-3 px-5">
//         {/* logo */}
//         <span>
//           <Link to="/" className="flex items-center" onClick={() => { handleSelect(''); window.scrollTo(0, 0); }}>
//             <img src={logo} className="h-12 w-40 object-cover" alt="Logo" />
//           </Link>
//         </span>
//         {/* button */}
//         <button
//           onClick={() => setIsOpen(!isOpen)}
//           type="button"
//           className="items-center p-2 ml-3 text-sm text-gray-500 rounded-lg lg:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200"
//         >
//           {/* <span className="sr-only">Open main menu</span> */}
//           <svg className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
//             <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16m-7 6h7" />
//           </svg>
//         </button>
//       </div>

//       {/* menu */}
//       <div className={`flex ${isOpen ? "flex" : "hidden"} bg-white` }>
//           <ul className='flex'>
//             {(isLoggedIn ? adminNavItems : navItems).map((item) => (
//               <li key={item.name} className="relative flex justify-evenly transition duration-300 cursor-pointer items-center group">
//                 <Link
//                   to={item.link}
//                   className={`flex items-center gap-x-2 py-2 pl-3 pr-4 ${selected === item.name.toLowerCase() ? 'text-[#F26222]' : 'text-gray-900'} md:p-0`}
//                   onClick={() => { handleSelect(item.name.toLowerCase()); window.scrollTo(0, 0); setIsOpen(false); }}
//                 >
//                   {item.icon && <img src={item.icon} alt={item.name} className="h-5 w-5" />}
//                   <span className="text-base">{item.name}</span>
//                 </Link>
//                 <div className={`w-full h-1 rounded-lg bg-[#F26222] mt-2 transition-opacity duration-300 ${selected === item.name.toLowerCase() ? 'opacity-100' : 'opacity-0'}`}></div>
//               </li>
//             ))}
//           </ul>
//       </div>
//     </nav>
//   );
// };

// export default Navbar;

import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import logo from '../../Assets/logo.svg';
import cabs from '../../Assets/taxi.svg';
import travel from '../../Assets/travelPackages.svg';
import shoots from '../../Assets/shoots.svg';
import EventManagement from '../../Assets/eventManagement.svg';
import Adventure from "../../Assets/adventure.svg";
import Wellness from "../../Assets/wellness.svg";
import Hotels from "../../Assets/hotels.svg";
import Villas from "../../Assets/villas.svg";
import dots from "../../Assets/threeDots.svg";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [selected, setSelected] = useState('');
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem('jwtToken');
    setIsLoggedIn(!!token);
  }, []);

  const handleSelect = (item) => {
    setSelected(item);
    setIsOpen(false);
  };


  // Navigation items for desktop view
  const navItems = [
    { name: 'Hotels', icon: Hotels, link: '/hotels' },
    { name: 'Villas', icon: Villas, link: '/villas' },
    { name: 'Travel Packages', icon: travel, link: '/travel-packages' },
    { name: 'Transportation', icon: cabs, link: '/transportation' },
    { name: 'Adventure', icon: Adventure, link: '/adventure' },
    {
      name: "Others", icon: dots, link: '',
      subItems: [
        { name: 'Photography & Videography', icon: shoots, link: '/photoShoots' },
        { name: 'Event Management', icon: EventManagement, link: '/eventManagement' },
        { name: 'Wellness and Relaxation', icon: Wellness, link: '/wellness' },
      ],
    },
  ];

  // Navigation items for mobile view (flat list)
  const navItems2 = [
    { name: 'Hotels', icon: Hotels, link: '/hotels' },
    { name: 'Villas', icon: Villas, link: '/villas' },
    { name: 'Travel Packages', icon: travel, link: '/travel-packages' },
    { name: 'Transportation', icon: cabs, link: '/transportation' },
    { name: 'Adventure', icon: Adventure, link: '/adventure' },
    { name: 'Photography & Videography', icon: shoots, link: '/photoShoots' },
    { name: 'Event Management', icon: EventManagement, link: '/eventManagement' },
    { name: 'Wellness and Relaxation', icon: Wellness, link: '/wellness' },
  ];

  // Admin navigation items for desktop
  const adminNavItemsDesktop = [
    { name: 'Dashboard', link: '/admin/dashboard' },
    ...navItems
  ];

  // Admin navigation items for mobile
  const adminNavItemsMobile = [
    { name: 'Dashboard', link: '/admin/dashboard' },
    ...navItems2
  ];

  return (
    <nav className="bg-white fixed w-full z-50 top-0 left-0 right-0 drop-shadow-lg">
      <div className="max-w-screen-2xl flex flex-wrap items-center justify-between mx-auto py-3 px-5">
        {/* Logo */}
        <Link to="/" className="flex items-center" onClick={() => { handleSelect(''); window.scrollTo(0, 0); }}>
          <img src={logo} className="h-12 w-40 object-cover" alt="Logo" />
        </Link>

        {/* Mobile Menu Toggle */}
        <button
          onClick={() => setIsOpen(!isOpen)}
          type="button"
          className="lg:hidden inline-flex items-center p-2 ml-3 text-sm text-gray-500 rounded-lg hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200"
        >
          <svg className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            {isOpen ? (
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
            ) : (
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16m-7 6h7" />
            )}
          </svg>
        </button>

        {/* Desktop Navigation */}
        <div className="hidden lg:flex lg:items-center lg:w-auto">
          <ul className="font-medium flex flex-row space-x-4">
            {(isLoggedIn ? adminNavItemsDesktop : navItems).map((item) => (
              <li key={item.name} className="relative group">
                {item.subItems ? (
                  // If item has subItems, render the dropdown
                  <>
                    <div
                      className={`flex items-center gap-x-2  py-2 cursor-pointer ${selected === item.name.toLowerCase() ? 'text-[#F26222]' : 'text-gray-900'}`}
                    >
                      {item.icon && <img src={item.icon} alt={item.name} className="h-5 w-5" />}
                      <span className="text-base">{item.name}</span>
                    </div>
                    {/* Dropdown menu */}
                    <ul className="absolute right-0 px-2 top-full hidden group-hover:block bg-white shadow-lg z-50">
                      {item.subItems.map((subItem) => (
                        <li key={subItem.name} className="whitespace-nowrap">
                          <Link
                            to={subItem.link}
                            className="flex justify-start items-center px-4 py-2 text-gray-900 hover:bg-gray-100"
                            onClick={() => { handleSelect(subItem.name.toLowerCase()); window.scrollTo(0, 0); }}
                          >
                            {subItem.icon && <img src={subItem.icon} alt={subItem.name} className="h-5 w-5 mr-2" />}
                            <span>{subItem.name}</span>
                          </Link>
                        </li>
                      ))}
                    </ul>
                    <div className={`w-full h-1 rounded-lg bg-[#F26222] mt-2 transition-opacity duration-300 ${selected === item.name.toLowerCase() ? 'opacity-100' : 'opacity-0'}`}></div>
                  </>
                ) : (
                  // If item does not have subItems, render as before
                  <>
                    <Link
                      to={item.link}
                      className={`flex items-center gap-x-2 py-2 ${selected === item.name.toLowerCase() ? 'text-[#F26222]' : 'text-gray-900'}`}
                      onClick={() => { handleSelect(item.name.toLowerCase()); window.scrollTo(0, 0); }}
                    >
                      {item.icon && <img src={item.icon} alt={item.name} className="h-5 w-5" />}
                      <span className="text-base">{item.name}</span>
                    </Link>
                    <div className={`w-full h-1 rounded-lg bg-[#F26222] mt-2 transition-opacity duration-300 ${selected === item.name.toLowerCase() ? 'opacity-100' : 'opacity-0'}`}></div>
                  </>
                )}
              </li>
            ))}
          </ul>
        </div>

        {/* Mobile Navigation */}
        {isOpen && (
          <div className="lg:hidden w-full absolute left-0 top-full bg-white shadow-lg">
            <div className="overflow-x-auto">
              <ul className="flex flex-row px-4 py-2 whitespace-nowrap space-x-4">
                {(isLoggedIn ? adminNavItemsMobile : navItems2).map((item) => (
                  <li
                    key={item.name}
                    className="flex-shrink-0 relative flex flex-col transition duration-300 cursor-pointer items-center group"
                  >
                    <Link
                      to={item.link}
                      className={`flex items-center gap-x-2 py-2 ${selected === item.name.toLowerCase() ? 'text-[#F26222]' : 'text-gray-900'}`}
                      onClick={() => { handleSelect(item.name.toLowerCase()); window.scrollTo(0, 0); setIsOpen(false); }}
                    >
                      {item.icon && <img src={item.icon} alt={item.name} className="h-5 w-5" />}
                      <span className="text-base">{item.name}</span>
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        )}
      </div>
    </nav>
  );
};

export default Navbar;
