import React from 'react'

function AboutUs() {
    return (
        <div className="bg-gray-100 min-h-screen py-12 px-4 sm:px-6 lg:px-8">
            <div className="max-w-4xl mx-auto bg-white shadow-xl rounded-lg overflow-hidden">
                <div className="px-6 py-8">
                    <h1 className="text-3xl font-bold text-gray-900 mb-6">About Us</h1>
                    
                    <p className="text-gray-700 mb-4">
                        Welcome to <strong className="text-indigo-600">Travoolah</strong>, your trusted companion for discovering 
                        unforgettable travel experiences. Our mission is to make every journey seamless, enriching, and tailored just for you. 
                        With a passion for exploration and a commitment to quality, we bring together the best in travel to help you create memories that last a lifetime.
                    </p>

                    <section className="mb-8">
                        <h2 className="text-2xl font-semibold text-gray-800 mb-4">Our Story</h2>
                        <p className="text-gray-700 mb-4">
                            Founded with a vision to redefine travel, Travoolah was born out of the love for adventure and a desire to connect people with the world's most enchanting destinations. 
                            Since our inception, we've worked tirelessly to curate services that cater to the unique preferences of every traveler, whether you're seeking luxury, adventure, or relaxation.
                        </p>
                    </section>

                    <section className="mb-8">
                        <h2 className="text-2xl font-semibold text-gray-800 mb-4">What We Offer</h2>
                        <ul className="list-disc list-inside text-gray-700 ml-4">
                            <li>**Hotel & Villa Bookings:** Handpicked accommodations for every budget and preference.</li>
                            <li>**Transportation Services:** Hassle-free car and bike rentals to explore at your pace.</li>
                            <li>**Curated Activities:** Dive into local culture with unique activities and guided tours.</li>
                            <li>**Event Management:** From corporate events to destination weddings, we ensure flawless execution.</li>
                        </ul>
                    </section>

                    <section className="mb-8">
                        <h2 className="text-2xl font-semibold text-gray-800 mb-4">Why Choose Us?</h2>
                        <p className="text-gray-700 mb-4">
                            At Travoolah, we prioritize your comfort and convenience. Here’s why travelers trust us:
                        </p>
                        <ul className="list-disc list-inside text-gray-700 ml-4">
                            <li><strong>Personalized Services:</strong> Tailored solutions for every journey.</li>
                            <li><strong>Trusted Partnerships:</strong> Collaborations with top-rated service providers.</li>
                            <li><strong>24/7 Support:</strong> Always here to assist you, wherever you are.</li>
                            <li><strong>Secure Payments:</strong> Safe and reliable online payment options.</li>
                        </ul>
                    </section>

                    <section className="mb-8">
                        <h2 className="text-2xl font-semibold text-gray-800 mb-4">Our Vision</h2>
                        <p className="text-gray-700 mb-4">
                            To inspire and empower travelers to explore the world, one unforgettable experience at a time. 
                            We believe in sustainable tourism and are committed to creating a positive impact on the communities and ecosystems we touch.
                        </p>
                    </section>

                    <section className="mb-8">
                        <h2 className="text-2xl font-semibold text-gray-800 mb-4">Contact Us</h2>
                        <p className="text-gray-700 mb-4">
                            Whether you have a question or need assistance planning your next adventure, we’re here to help. Reach out to us:
                        </p>
                        <ul className="list-none text-gray-700 ml-4">
                            <li><strong>Email:</strong> support@travoolah.com</li>
                            <li><strong>Phone:</strong> +91 987 654 3210</li>
                        </ul>
                    </section>
                </div>
            </div>
        </div>
    );
}

export default AboutUs