import React from 'react'

function TravelAi() {
  return (
    <div className='w-full'>
        <div className='text-center py-28'>
            <p className='text-2xl font-extrabold'>Comming soon To your website</p>
        </div>
    </div>
  )
}

export default TravelAi