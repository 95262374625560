import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import ContactForm from './Contact'; // Import the new contact form

function AdventureDetailsPage() {
  const { id } = useParams();
  const [adventure, setAdventure] = useState(null);
  const [showForm, setShowForm] = useState(false);

  useEffect(() => {
    const fetchAdventureData = async () => {
      try {
        const response = await axios.get(`https://api.travoolah.com/api/Adventure/${id}`);
        setAdventure(response.data);
      } catch (error) {
        console.error("Error fetching adventure details:", error);
      }
    };
    fetchAdventureData();
  }, [id]);

  if (!adventure) return <p>Loading...</p>;

  return (
    <div className="max-w-7xl mx-auto px-4 py-8 mt-12">
      {/* Title */}
      <h1 className="text-4xl font-semibold text-center">{adventure.title}</h1>

      {/* Book Now Button */}
      <div className="flex justify-center mt-4">
        <button
          className="bg-orange-500 text-white py-2 px-6 rounded hover:bg-orange-600"
          onClick={() => setShowForm(true)}
        >
          Book Now
        </button>
      </div>

      {/* Image Gallery */}
      <section className="mt-8">
        <div className="grid grid-cols-2 sm:grid-cols-3 gap-4">
          {adventure.images && adventure.images.length > 0 ? (
            adventure.images.map((image, index) => {
              const imageClasses = [
                "col-span-2 row-span-2", // Larger image for img[0]
                "col-span-1 row-span-1", // Normal size for img[1]
                "col-span-1 row-span-1", // Normal size for img[2]
                "col-span-1 row-span-2", // Taller image for img[3]
                "col-span-1 row-span-1", // Normal size for img[4]
              ];
              return (
                <img
                  key={index}
                  src={`https://api.travoolah.com/${image}`}
                  alt={`Hotel Image ${index + 1}`}
                  className={`w-full h-full object-cover rounded ${imageClasses[index] || ''}`}
                />
              );
            })
          ) : (
            <p className="text-center text-gray-500 col-span-3">No images available</p>
          )}
        </div>
      </section>

      {/* Location */}
      {adventure.location && (
        <section className="mt-8 text-center">
          <h2 className="text-2xl font-semibold">Location</h2>
          <p className="mt-2 text-gray-700">{adventure.location}</p>
        </section>
      )}

      {/* Description Section */}
      {adventure.overview && (
        <section className="mt-8 text-center">
          <h2 className="text-2xl font-semibold">Description</h2>
          <p className="mt-4 text-gray-700">{adventure.overview}</p>
        </section>
      )}

      {/* Price */}
      {adventure.price && (
        <section className="mt-8 text-center">
          <h2 className="text-2xl font-semibold">Price</h2>
          <p className="mt-4 text-2xl font-bold text-gray-800">₹{adventure.price}</p>
        </section>
      )}

      {/* Additional Information Section */}
      {adventure.additionalInfo && (
        <section className="mt-8 text-center">
          <h2 className="text-2xl font-semibold">Additional Information</h2>
          <p className="mt-4 text-gray-700">{adventure.additionalInfo}</p>
        </section>
      )}

      {/* Terms and Conditions Section */}
      {adventure.termCondition && (
        <section className="mt-8 text-center">
          <h2 className="text-2xl font-semibold">Terms & Conditions</h2>
          <p className="mt-4 text-gray-700">{adventure.termCondition}</p>
        </section>
      )}

      {/* Show contact form when "Book Now" is clicked */}
      {showForm && (
        <section className="mt-12">
          <ContactForm
            hotelId={id}
            hotelName={adventure.title}
            onSuccess={() => setShowForm(false)} // Close the form on success
          />
        </section>
      )}
    </div>
  );
}

export default AdventureDetailsPage;
