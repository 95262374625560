import React , {useState , useEffect} from 'react'
import EventCard from '../Common/EventCard';
import { useNavigate } from "react-router-dom";
import axios from 'axios';

function DestinationWedding() {

  const [destWed , setDestWed] = useState([]);

  // Define an async function to fetch data
  const getData = async () => {
    try {
      const response = await axios.get("https://api.travoolah.com/api/EventManagement");
      // Assuming the data is an array, set it directly
      console.log("res" , response)
      setDestWed(response.data);
    } catch (error) {
      console.error("Error fetching adventure data:", error);
    }
  };
  
    useEffect(() => {
      getData();
    }, []);
    const navigate = useNavigate();

    const handleCardClick = (id) => {
        navigate(`/eventManagement/${id}`)
    }

  return (
    <div className='flex justify-center px-4 sm:px-5 mt-10 sm:mt-20'>
      <div className='w-full max-w-6xl flex flex-col gap-y-5'>
        <h3 className='text-[#353535] text-xl sm:text-2xl md:text-3xl font-semibold text-center px-4 py-3 sm:py-5'>Destination Weddings</h3>
        <div className='relative'>
          <div className='flex overflow-x-auto pb-4 gap-4 sm:gap-6 snap-x justify-center'>
          {destWed.length > 0 ? destWed
              .filter((dest) => dest.eventManagementCategory?.name === "DestinationWedding") // Filter by category id if needed
              .map((adventure, index) => (
                <div key={index} className='flex-none w-[280px] sm:w-[320px] snap-start'>
                  <EventCard
                    img={adventure.images && adventure.images.length > 0 ? `https://api.travoolah.com/${adventure.images[0]}` : '/placeholder-image.jpg'} // Fallback to placeholder
                    // Adjust the path if needed
                    title={adventure.title} 
                    oldPrice={`${adventure.price + 1500}`}
                    newPrice={adventure.price} // Update as per logic
                    onClick={()=>{
                      handleCardClick(adventure.id)
                    }}
                  />
                </div>
              )) :  <p>No data avalible</p>}
          </div>
        </div>
      </div>
    </div>
  )
}

export default DestinationWedding