import React, {useState , useEffect} from 'react'
import axios from 'axios';
import EventCard from '../Common/EventCard';
import { useNavigate } from "react-router-dom";


function ThemeParties() {
  const [theme , setTheme] = useState([]);

  // Define an async function to fetch data
  const getData = async () => {
    try {
      const response = await axios.get("https://api.travoolah.com/api/EventManagement");
      // Assuming the data is an array, set it directly
      setTheme(response.data);
    } catch (error) {
      console.error("Error fetching adventure data:", error);
    }
  };
  
    useEffect(() => {
      getData();
    }, []);

    const navigate = useNavigate();

    const handleCardClick = (id) => {
        navigate(`/eventManagement/${id}`)
    }



  return (
    <div className='flex justify-center px-4 sm:px-5 mt-10 sm:mt-20'>
      <div className='w-full max-w-6xl flex flex-col gap-y-5'>
        <h3 className='text-[#353535] text-xl sm:text-2xl md:text-3xl font-semibold text-center px-4 py-3 sm:py-5'>Theme Parties & Celebrations</h3>
        <div className='relative'>
          <div className='flex overflow-x-auto pb-4 gap-4 sm:gap-6 snap-x justify-center'>
          {theme.length > 0 ? theme
              .filter((themeand) => themeand.eventManagementCategory?.name === "ThemePartiesAndCelebrations") // Filter by category id if needed
              .map((adventure, index) => (
                <div key={index} className='flex-none w-[280px] sm:w-[320px] snap-start'>
                  <EventCard
                    img={adventure.images && adventure.images.length > 0 ? `https://api.travoolah.com/${adventure.images[0]}` : '/placeholder-image.jpg'} // Fallback to placeholder
                    title={adventure.title} 
                    oldPrice={`${adventure.price + 1500}`}
                    newPrice={adventure.price}
                    onClick={()=>{
                      handleCardClick(adventure.id)
                    }}
                  />
                </div>
              )):  <p>No data avalible</p>}
          </div>
        </div>
      </div>
    </div>
  )
}

export default ThemeParties