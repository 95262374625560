import React, { useEffect, useState } from 'react';
import axios from "axios";
import { PlusCircle, Edit2, Trash2, XCircle } from 'lucide-react';

function Wellness() {

  const [categories, setCategories] = useState([]);
  const [relaxationPackages, setRelaxationPackages] = useState([]);
  const [relaxationForm, setRelaxationForm] = useState({
    id: null,
    images: [],
    existingImages: [],
    title: '',
    price: '',
    relaxationCategoryId: '',
    additionalInfo: '',
    overview: '',
    termCondition: ''
  });
  const [errors, setErrors] = useState({});

  const fetchCategories = async () => {
    try {
      const response = await axios.get('https://api.travoolah.com/api/RelaxationCategory');
      setCategories(response.data);
    } catch (e) {
      console.error("Error fetching categories:", e);
    }
  }

  const fetchRelaxationPackages = async () => {
    try {
      const response = await axios.get('https://api.travoolah.com/api/Relaxation');
      setRelaxationPackages(response.data);
    } catch (e) {
      console.error("Error fetching relaxation packages:", e);
    }
  }

  useEffect(() => {
    fetchCategories();
    fetchRelaxationPackages();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setRelaxationForm({ ...relaxationForm, [name]: value });
  };

  const handleImageChange = (e) => {
    setRelaxationForm({ ...relaxationForm, images: Array.from(e.target.files) });
  };

  const handleImageDelete = (imagePath) => {
    setRelaxationForm((prev) => ({
      ...prev,
      existingImages: prev.existingImages.filter((img) => img !== imagePath),
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrors({});
    const formData = new FormData();

    if (relaxationForm.id) {
      formData.append("Id", relaxationForm.id);
    }
    formData.append("Title", relaxationForm.title);
    formData.append("Price", relaxationForm.price);
    formData.append("RelaxationCategoryId", relaxationForm.relaxationCategoryId);
    formData.append("AdditionalInfo", relaxationForm.additionalInfo);
    formData.append("Overview", relaxationForm.overview);
    formData.append("TermCondition", relaxationForm.termCondition);

    // Append new images
    relaxationForm.images.forEach(image => {
      formData.append("imageFiles", image);
    });

    // Append existing images
    relaxationForm.existingImages.forEach(imagePath => {
      formData.append("Images", imagePath);
    });

    try {
      if (relaxationForm.id) {
        await axios.put(`https://api.travoolah.com/api/Relaxation/${relaxationForm.id}`, formData, {
          headers: { 'Content-Type': 'multipart/form-data' }
        });
      } else {
        await axios.post('https://api.travoolah.com/api/Relaxation', formData, {
          headers: { 'Content-Type': 'multipart/form-data' }
        });
      }
      resetForm();
      fetchRelaxationPackages();
    } catch (error) {
      console.error("Error submitting form:", error);
      if (error.response && error.response.data) {
        setErrors(error.response.data.errors || {});
        alert(`Error: ${JSON.stringify(error.response.data)}`);
      }
    }
  };

  const handleEdit = (pkg) => {
    setRelaxationForm({
      id: pkg.id,
      title: pkg.title || '',
      price: pkg.price || '',
      relaxationCategoryId: pkg.relaxationCategoryId || '',
      images: [],
      existingImages: pkg.images || [],
      additionalInfo: pkg.additionalInfo || '',
      overview: pkg.overview || '',
      termCondition: pkg.termCondition || ''
    });
  };

  const handleDelete = async (id) => {
    if (window.confirm("Are you sure you want to delete this package?")) {
      try {
        await axios.delete(`https://api.travoolah.com/api/Relaxation/${id}`);
        fetchRelaxationPackages();
      } catch (error) {
        console.error("Error deleting package:", error);
      }
    }
  };

  const resetForm = () => {
    setRelaxationForm({
      id: null,
      images: [],
      existingImages: [],
      title: '',
      price: '',
      relaxationCategoryId: '',
      additionalInfo: '',
      overview: '',
      termCondition: ''
    });
    setErrors({});
  };


  return (
    <div className="max-w-4xl mx-auto p-6 bg-white shadow-lg rounded-lg">
      <h2 className="text-2xl font-bold mb-6 text-gray-800">Wellness Packages</h2>
      <form onSubmit={handleSubmit} className="space-y-4">
        {/* Existing Images */}
        {relaxationForm.existingImages.length > 0 && (
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">Existing Images</label>
            <div className="flex flex-wrap gap-4">
              {relaxationForm.existingImages.map((imagePath, index) => (
                <div key={index} className="relative">
                  <img
                    src={`https://api.travoolah.com/${imagePath}`}
                    alt="Relaxation"
                    className="w-32 h-32 object-cover rounded-md"
                  />
                  <button
                    type="button"
                    onClick={() => handleImageDelete(imagePath)}
                    className="absolute top-1 right-1 bg-red-500 text-white rounded-full p-1 hover:bg-red-600"
                  >
                    <XCircle size={16} />
                  </button>
                </div>
              ))}
            </div>
          </div>
        )}

        {/* Image Upload */}
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2" htmlFor="images">Upload Images</label>
          <input
            id="images"
            type="file"
            accept="image/*"
            multiple
            onChange={handleImageChange}
            className="mt-1 block w-full text-sm text-gray-500
                       file:mr-4 file:py-2 file:px-4
                       file:rounded-md file:border-0
                       file:text-sm file:font-semibold
                       file:bg-blue-50 file:text-blue-700
                       hover:file:bg-blue-100"
          />
          {errors.Images && <p className="text-red-500 text-sm mt-1">{errors.Images}</p>}
          <span className='text-xs text-gray-500 font-bold'>Max 6 images</span>

        </div>

        {/* Title */}
        <div>
          <input
            type='text'
            name='title'
            value={relaxationForm.title}
            onChange={handleChange}
            placeholder='Title'
            required
            className="w-full p-2 border border-gray-300 rounded-md"
          />
          {errors.Title && <p className="text-red-500 text-sm mt-1">{errors.Title}</p>}
        </div>

        {/* Price */}
        <div>
          <input
            type='number'
            name='price'
            value={relaxationForm.price}
            onChange={handleChange}
            placeholder='Price'
            required
            className="w-full p-2 border border-gray-300 rounded-md"
          />
          {errors.Price && <p className="text-red-500 text-sm mt-1">{errors.Price}</p>}
        </div>

        {/* Additional Info */}
        <div>
          <textarea
            name="additionalInfo"
            value={relaxationForm.additionalInfo}
            onChange={handleChange}
            placeholder="Additional Information"
            className="w-full p-2 border border-gray-300 rounded-md h-24"
          />
          {errors.AdditionalInfo && <p className="text-red-500 text-sm mt-1">{errors.AdditionalInfo}</p>}
        </div>

        {/* Overview */}
        <div>
          <textarea
            name="overview"
            value={relaxationForm.overview}
            onChange={handleChange}
            placeholder="Overview"
            className="w-full p-2 border border-gray-300 rounded-md h-24"
          />
          {errors.Overview && <p className="text-red-500 text-sm mt-1">{errors.Overview}</p>}
        </div>

        {/* Terms and Conditions */}
        <div>
          <textarea
            name="termCondition"
            value={relaxationForm.termCondition}
            onChange={handleChange}
            placeholder="Terms and Conditions"
            className="w-full p-2 border border-gray-300 rounded-md h-24"
          />
          {errors.TermCondition && <p className="text-red-500 text-sm mt-1">{errors.TermCondition}</p>}
        </div>

        {/* Category Select */}
        <div>
          <select
            name="relaxationCategoryId"
            value={relaxationForm.relaxationCategoryId}
            onChange={handleChange}
            required
            className="w-full p-2 border border-gray-300 rounded-md"
          >
            <option value="">Select a category</option>
            {categories.map(cat => (
              <option key={cat.id} value={cat.id}>{cat.name}</option>
            ))}
          </select>
          {errors.RelaxationCategoryId && <p className="text-red-500 text-sm mt-1">{errors.RelaxationCategoryId}</p>}
        </div>

        {/* Submit Button */}
        <button
          type="submit"
          className="w-full bg-blue-600 text-white py-2 px-4 rounded-md hover:bg-blue-700 transition duration-300 flex items-center justify-center"
        >
          <PlusCircle className="mr-2" size={20} />
          {relaxationForm.id ? 'Update Package' : 'Add Package'}
        </button>
      </form>

      <h3 className='text-xl font-semibold mt-8 mb-4 text-gray-800'>Existing Wellness Packages</h3>
      <ul className="divide-y divide-gray-200">
        {relaxationPackages.map(pkg => (
          <li key={pkg.id} className="py-4 flex justify-between items-center">
            <span className="text-lg text-gray-700">{pkg.title}</span>
            <div>
              <button
                onClick={() => handleEdit(pkg)}
                className="bg-yellow-500 text-white rounded-md p-2 mr-2 hover:bg-yellow-600 transition duration-300"
              >
                <Edit2 size={18} />
              </button>
              <button
                onClick={() => handleDelete(pkg.id)}
                className="bg-red-500 text-white rounded-md p-2 hover:bg-red-600 transition duration-300"
              >
                <Trash2 size={18} />
              </button>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default Wellness;
