import React, { useState, useEffect } from 'react';
import axios from "axios";
import { Plus, Trash2 } from 'lucide-react';

function TravelItenaries() {
  const [travelPackages, setTravelPackages] = useState([]);
  const [selectedPackageId, setSelectedPackageId] = useState('');
  const [dayWiseItineraries, setDayWiseItineraries] = useState([]);

  useEffect(() => {
    fetchTravelPackages();
  }, []);

  useEffect(() => {
    if (selectedPackageId) {
      fetchItineraries(selectedPackageId);
    } else {
      setDayWiseItineraries([]);
    }
  }, [selectedPackageId]);

  const fetchTravelPackages = async () => {
    try {
      const response = await axios.get('https://api.travoolah.com/api/TravelPackage');
      setTravelPackages(response.data);
    } catch (e) {
      console.error("Error fetching travel packages:", e);
    }
  };

  const fetchItineraries = async (packageId) => {
    try {
      const response = await axios.get(`https://api.travoolah.com/api/TravelPackage/${packageId}`);
      setDayWiseItineraries(response.data.dayWiseItinerary || []);
    } catch (e) {
      console.error("Error fetching itineraries:", e);
    }
  };

  const addItinerary = () => {
    if (!selectedPackageId) {
      alert("Please select a travel package first.");
      return;
    }
    const newItinerary = {
      id: null,
      dayNumber: '',
      title: '',
      details: '',
      travelPackageId: selectedPackageId,
    };
    setDayWiseItineraries((prev) => [...prev, newItinerary]);
  };

  const removeItinerary = (index) => {
    const updatedItineraries = [...dayWiseItineraries];
    const itinerary = updatedItineraries[index];

    if (itinerary.id) {
      // Mark the itinerary for deletion
      itinerary._delete = true;
    } else {
      // Remove new itineraries from the list
      updatedItineraries.splice(index, 1);
    }

    setDayWiseItineraries(updatedItineraries);
  };

  const handleItineraryChange = (index, field, value) => {
    const updatedItineraries = [...dayWiseItineraries];
    updatedItineraries[index][field] = value;
    setDayWiseItineraries(updatedItineraries);
  };

  const handlePackageChange = (e) => {
    setSelectedPackageId(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    for (const itinerary of dayWiseItineraries) {
      if (itinerary._delete) {
        // Delete itineraries marked for deletion
        try {
          await axios.delete(`https://api.travoolah.com/api/TravelPackageDayWiseItinerary/${itinerary.id}`);
        } catch (error) {
          console.error("Error deleting itinerary:", error);
        }
      } else {
        // Prepare payload
        const payload = {
          id: itinerary.id || undefined,
          dayNumber: itinerary.dayNumber,
          title: itinerary.title,
          details: itinerary.details,
          travelPackageId: itinerary.travelPackageId,
        };

        // Validate itinerary data
        const validationError = validateItinerary(payload);
        if (validationError) {
          alert(`Error in itinerary for Day ${itinerary.dayNumber || ''}: ${validationError}`);
          return;
        }

        // Save or update itineraries
        try {
          if (itinerary.id) {
            await axios.put(
              `https://api.travoolah.com/api/TravelPackageDayWiseItinerary/${itinerary.id}`,
              payload,
              {
                headers: { 'Content-Type': 'application/json' },
              }
            );
          } else {
            await axios.post(
              'https://api.travoolah.com/api/TravelPackageDayWiseItinerary',
              payload,
              {
                headers: { 'Content-Type': 'application/json' },
              }
            );
          }
        } catch (error) {
          console.error("Error saving itinerary:", error);
          if (error.response) {
            console.error("Server responded with an error:", error.response.data);
          }
        }
      }
    }

    // Refresh itineraries after submission
    fetchItineraries(selectedPackageId);
    alert("Itineraries have been saved successfully!");
  };

  const validateItinerary = (itinerary) => {
    if (!itinerary.dayNumber) return "Day number is required.";
    if (!itinerary.title) return "Title is required.";
    if (!itinerary.details) return "Details are required.";
    if (!itinerary.travelPackageId) return "Travel package ID is required.";
    return null; // No errors
  };

  return (
    <div className="max-w-3xl mx-auto p-6 bg-white shadow-lg rounded-lg">
      <h2 className="text-2xl font-bold mb-6 text-gray-800">Manage Itineraries</h2>

      {/* Travel Package Selection */}
      <div className="mb-6">
        <label htmlFor="travelPackage" className="block text-gray-700 font-medium mb-2">
          Select Travel Package:
        </label>
        <select
          id="travelPackage"
          value={selectedPackageId}
          onChange={handlePackageChange}
          className="w-full p-2 border border-gray-300 rounded-md"
        >
          <option value="">-- Select a Travel Package --</option>
          {travelPackages.map(pkg => (
            <option key={pkg.id} value={pkg.id}>
              {pkg.title}
            </option>
          ))}
        </select>
      </div>

      {/* Day-wise Itineraries */}
      {selectedPackageId && (
        <form onSubmit={handleSubmit}>
          <div className="md:col-span-2">
            {dayWiseItineraries.map((itinerary, index) => (
              !itinerary._delete && (
                <div key={index} className="border p-4 mb-4 rounded-md">
                  <div className="flex items-center justify-between mb-2">
                    <span className="font-medium">Day {itinerary.dayNumber || index + 1}</span>
                    <button
                      type="button"
                      onClick={() => removeItinerary(index)}
                      className="p-1 bg-red-500 text-white rounded-full hover:bg-red-600"
                    >
                      <Trash2 size={16} />
                    </button>
                  </div>
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
                    <input
                      type="number"
                      value={itinerary.dayNumber}
                      onChange={(e) => handleItineraryChange(index, 'dayNumber', e.target.value)}
                      placeholder="Day Number"
                      className="w-full p-2 border border-gray-300 rounded-md"
                      required
                    />
                    <input
                      type="text"
                      value={itinerary.title}
                      onChange={(e) => handleItineraryChange(index, 'title', e.target.value)}
                      placeholder="Title"
                      className="w-full p-2 border border-gray-300 rounded-md"
                      required
                    />
                  </div>
                  <textarea
                    value={itinerary.details}
                    onChange={(e) => handleItineraryChange(index, 'details', e.target.value)}
                    placeholder="Details"
                    className="w-full p-2 border border-gray-300 rounded-md mt-2"
                    required
                  />
                </div>
              )
            ))}
            <button
              type="button"
              onClick={addItinerary}
              className="mt-2 p-2 bg-green-500 text-white rounded-md hover:bg-green-600 flex items-center"
            >
              <Plus size={20} className="mr-1" />
              Add Day-wise Itinerary
            </button>
          </div>

          {/* Submit Button */}
          <div className="mt-6">
            <button
              type="submit"
              className="w-full bg-blue-600 text-white py-2 px-4 rounded-md hover:bg-blue-700 transition duration-300 flex items-center justify-center"
            >
              Save All Itineraries
            </button>
          </div>
        </form>
      )}
    </div>
  );
}

export default TravelItenaries;
