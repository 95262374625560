import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import ContactForm from './Contact'; // Import the new contact form

function VillasDetail() {
  const { id } = useParams();
  const [villa, setVilla] = useState(null);
  const [showForm, setShowForm] = useState(false);

  useEffect(() => {
    const fetchVillaData = async () => {
      try {
        const response = await axios.get(`https://api.travoolah.com/api/Villa/${id}`);
        setVilla(response.data);
        console.log(response);
      } catch (error) {
        console.error('Error fetching villa details:', error);
      }
    };
    fetchVillaData();
  }, [id]);

  if (!villa) return <p>Loading...</p>;

  return (
    <div className="max-w-7xl mx-auto px-4 py-7 mt-20">
      {/* Header Section */}
      <header className="text-center">
        <h1 className="text-4xl font-bold mb-4">{villa.title}</h1>
        <button
          className="bg-orange-500 text-white py-2 px-6 rounded hover:bg-orange-600"
          onClick={() => setShowForm(true)}
        >
          Book Now
        </button>
      </header>

      {/* Image Gallery */}
      <section className="mt-8">
        <div className="grid grid-cols-2 sm:grid-cols-3 gap-4">
          {villa.images && villa.images.length > 0 ? (
            villa.images.map((image, index) => {
              const imageClasses = [
                "col-span-2 row-span-2", // Larger image for img[0]
                "col-span-1 row-span-1", // Normal size for img[1]
                "col-span-1 row-span-1", // Normal size for img[2]
                "col-span-1 row-span-2", // Taller image for img[3]
                "col-span-1 row-span-1", // Normal size for img[4]
              ];
              return (
                <img
                  key={index}
                  src={`https://api.travoolah.com/${image}`}
                  alt={`Hotel Image ${index + 1}`}
                  className={`w-full h-full object-cover rounded ${imageClasses[index] || ''}`}
                />
              );
            })
          ) : (
            <p className="text-center text-gray-500 col-span-3">No images available</p>
          )}
        </div>
      </section>


      {/* Description Section */}
      <section className="mt-10">
        {villa.duration && (
          <p className="text-center mt-4 text-lg font-semibold">{villa.duration}</p>
        )}
        {villa.overview && (
          <p className="text-center text-gray-700 mt-4">{villa.overview}</p>
        )}
        {villa.price && (
          <p className="text-center text-lg font-semibold mt-4">Price: ₹{villa.price} <span className='text-gray-500 text-xs'>/Per Night</span></p>
        )}
      </section>

      {/* Additional Information Section */}
      {villa.additionalInfo && villa.additionalInfo.trim().length > 0 && (
        <section className="mt-12">
          <h2 className="text-3xl font-semibold text-center">Additional Information</h2>
          <div className="mt-6 text-center">
            <p className="text-gray-700">{villa.additionalInfo}</p>
          </div>
        </section>
      )}

      {/* Terms and Conditions Section */}
      {villa.termCondition && villa.termCondition.trim().length > 0 && (
        <section className="mt-12">
          <h2 className="text-3xl font-semibold text-center">Terms and Conditions</h2>
          <div className="mt-6 text-center">
            <p className="text-gray-700">{villa.termCondition}</p>
          </div> 
        </section>
      )}

      {/* Show the Contact Form when the Book Now button is clicked */}
      {showForm && (
        <section className="mt-12">
          <ContactForm
            hotelId={id}
            hotelName={villa.title}
            onSuccess={() => setShowForm(false)} // Close the form on success
          />
        </section>
      )}
    </div>
  );
}

export default VillasDetail;
