import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import ContactForm from './Contact';

function EventDetailPage() {
  const { id } = useParams();
  const [eventData, setEventData] = useState(null);
  const [showForm, setShowForm] = useState(false);

  useEffect(() => {
    const fetchEventData = async () => {
      try {
        const response = await axios.get(`https://api.travoolah.com/api/EventManagement/${id}`);
        setEventData(response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchEventData();
  }, [id]);

  if (!eventData) return <p>Loading...</p>;

  return (
    <div className="max-w-7xl mx-auto px-4 mt-20">
      <header className="text-center mb-8">
        <h1 className="text-5xl font-extrabold text-gray-800 mb-6">{eventData.title}</h1>
        <button
          className="bg-orange-500 text-white py-2 px-6 rounded text-lg hover:bg-orange-600"
          onClick={() => setShowForm(true)}
        >
          Book Now
        </button>
      </header>

      {/* Image Gallery */}
      <section className="mt-8">
        <div className="grid grid-cols-2 sm:grid-cols-3 gap-4">
          {eventData.images && eventData.images.length > 0 ? (
            eventData.images.map((image, index) => {
              const imageClasses = [
                "col-span-2 row-span-2", // Larger image for img[0]
                "col-span-1 row-span-1", // Normal size for img[1]
                "col-span-1 row-span-1", // Normal size for img[2]
                "col-span-1 row-span-2", // Taller image for img[3]
                "col-span-1 row-span-1", // Normal size for img[4]
              ];
              return (
                <img
                  key={index}
                  src={`https://api.travoolah.com/${image}`}
                  alt={`Hotel Image ${index + 1}`}
                  className={`w-full h-full object-cover rounded ${imageClasses[index] || ''}`}
                />
              );
            })
          ) : (
            <p className="text-center text-gray-500 col-span-3">No images available</p>
          )}
        </div>
      </section>

      {/* Description Section */}
      <section className="mb-12">
        <h2 className="text-3xl font-bold text-gray-800 mb-6 text-center">Description</h2>
        <p className="text-center text-gray-700 text-lg">{eventData.description}</p>
      </section>

      {/* Price Section */}
      <section className="mb-12">
        <h2 className="text-3xl font-bold text-gray-800 mb-6 text-center">Price</h2>
        <p className="text-center text-2xl font-bold">₹{eventData.price}</p>
      </section>

      {/* Package Includes */}
      {eventData.packageIncludes && eventData.packageIncludes.length > 0 && (
        <section className="mb-12">
          <h2 className="text-3xl font-bold text-gray-800 mb-6 text-center">Package Includes</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
            {eventData.packageIncludes.map((include, index) => (
              <div
                key={index}
                className="p-4 bg-white shadow rounded-lg text-center border border-gray-200"
              >
                <h3 className="font-semibold text-lg mb-2">{include.title}</h3>
                <p className="text-sm text-gray-600">{include.description}</p>
              </div>
            ))}
          </div>
        </section>
      )}

      {/* Day Wise Itineraries */}
      {eventData.dayWiseItineraries && eventData.dayWiseItineraries.length > 0 && (
        <section className="mb-12">
          <h2 className="text-3xl font-semibold text-center">Day Wise Itineraries</h2>
          <div className="mt-6">
            {eventData.dayWiseItineraries
              .sort((a, b) => a.dayNumber - b.dayNumber)
              .map((itinerary, index) => (
                <div key={index} className="bg-white shadow-md rounded p-6 mt-4">
                  <div className="flex items-center">
                    <div className="bg-orange-500 text-white w-12 h-12 flex items-center justify-center rounded-md text-lg font-semibold">
                      Day {itinerary.dayNumber}
                    </div>
                    <h3 className="text-lg font-semibold ml-4">{itinerary.title}</h3>
                  </div>
                  <p className="text-gray-700 mt-2 ml-16">{itinerary.details}</p>
                </div>
              ))}
          </div>
        </section>
      )}

      {/* Addons */}
      {eventData.addons && eventData.addons.length > 0 && (
        <section className="mb-12">
          <h2 className="text-3xl font-bold text-gray-800 mb-6 text-center">Available Add-ons</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            {eventData.addons.map((addon, index) => (
              <div
                key={index}
                className="p-4 bg-white shadow rounded-lg text-center border border-gray-200"
              >
                <h3 className="font-semibold text-lg mb-2">{addon.title}</h3>
                <p className="text-sm text-gray-600">{addon.description}</p>
              </div>
            ))}
          </div>
        </section>
      )}

      {showForm && (
        <ContactForm
          hotelId={id}
          hotelName={eventData.title}
          onSuccess={() => setShowForm(false)} // Close the form on success
        />
      )}
    </div>
  );
}

export default EventDetailPage;
