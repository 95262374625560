import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import ContactForm from './Contact'; // Import the contact form

function PhotoShootDetailPage() {
  const { id } = useParams();
  const [shoot, setShoot] = useState(null);
  const [showForm, setShowForm] = useState(false); // State to toggle form visibility

  useEffect(() => {
    const fetchShootData = async () => {
      try {
        const response = await axios.get(`https://api.travoolah.com/api/Shoot/${id}`);
        setShoot(response.data);
        console.log(response);
      } catch (error) {
        console.error("Error fetching photoshoot details:", error);
      }
    };
    fetchShootData();
  }, [id]);

  if (!shoot) return <p>Loading...</p>;

  return (
    <div className="max-w-7xl mx-auto px-4 py-8 mt-12">
      {/* Photoshoot Title and Booking Button */}
      <h1 className="text-4xl font-semibold text-center">{shoot.title}</h1>
      <div className="flex justify-center mt-4">
        <button
          className="bg-orange-500 text-white py-2 px-6 rounded hover:bg-orange-600"
          onClick={() => setShowForm(true)} // Show the contact form when clicked
        >
          Book Now
        </button>
      </div>

      {/* Image Gallery */}
      <section className="mt-8">
        <div className="grid grid-cols-2 sm:grid-cols-3 gap-4">
          {shoot.images && shoot.images.length > 0 ? (
            shoot.images.map((image, index) => {
              const imageClasses = [
                "col-span-2 row-span-2", // Larger image for img[0]
                "col-span-1 row-span-1", // Normal size for img[1]
                "col-span-1 row-span-1", // Normal size for img[2]
                "col-span-1 row-span-2", // Taller image for img[3]
                "col-span-1 row-span-1", // Normal size for img[4]
              ];
              return (
                <img
                  key={index}
                  src={`https://api.travoolah.com/${image}`}
                  alt={`Hotel Image ${index + 1}`}
                  className={`w-full h-full object-cover rounded ${imageClasses[index] || ''}`}
                />
              );
            })
          ) : (
            <p className="text-center text-gray-500 col-span-3">No images available</p>
          )}
        </div>
      </section>


      {/* Photoshoot Overview */}
      {shoot.overview && (
        <div className="mt-8">
          <h2 className="text-2xl font-semibold text-center">Photoshoot Overview</h2>
          <p className="text-gray-700 mt-4 text-center">{shoot.overview}</p>
        </div>
      )}

      {/* Price */}
      <div className="mt-4 text-center">
        <p className="text-lg font-semibold">Price: ₹{shoot.price}</p>
      </div>

      {/* Additional Information */}
      {shoot.additionalInfo && (
        <div className="mt-8">
          <h2 className="text-2xl font-semibold text-center">Additional Information</h2>
          <p className="text-gray-700 mt-4 text-center">{shoot.additionalInfo}</p>
        </div>
      )}

      {/* Terms and Conditions */}
      {shoot.termCondition && (
        <div className="mt-8">
          <h2 className="text-2xl font-semibold text-center">Terms and Conditions</h2>
          <p className="text-gray-700 mt-4 text-center">{shoot.termCondition}</p>
        </div>
      )}

      {/* Shoot Category */}
      {/* {shoot.shootCategory && (
        <div className="mt-8">
          <h2 className="text-2xl font-semibold text-center">Category</h2>
          <p className="text-gray-700 mt-4 text-center">{shoot.shootCategory.name}</p>
        </div>
      )} */}

      {/* Show contact form when "Book Now" is clicked */}
      {showForm && (
        <ContactForm
          shootId={id} // Pass shoot ID for booking
          shootTitle={shoot.title} // Pass shoot title for booking
          onSuccess={() => setShowForm(false)} // Close the form on successful submission
        />
      )}
    </div>
  );
}

export default PhotoShootDetailPage;
