import React, { useEffect, useState } from 'react';
import axios from "axios";
import { PlusCircle, Edit2, Trash2 } from 'lucide-react';

function HotelsAdmin() {
    const [travelPackages, setTravelPackages] = useState([]);
    const [transportForm, setTransportForm] = useState({
        Id: null,
        title: '',
        description: '',
        duration: '',
        email: '',
        AdditionalInfo: '',
        Overview: '',
        TermCondition: '',
        price: '',
        Images: []
    });

    const fetchTravel = async () => {
        try {
            const response = await axios.get('https://api.travoolah.com/api/Hotel');
            setTravelPackages(response.data);
        } catch (e) {
            console.error("Error fetching data:", e);
            alert('Error fetching hotels. Please try again.');
        }
    };
    useEffect(() => {
        fetchTravel();
    }, []);
    
    const handleChange = (e) => {
        const { name, value } = e.target;
        setTransportForm(prev => ({
            ...prev,
            [name]: value
        }));
    };
    
    const handleImageChange = (e) => {
        setTransportForm(prev => ({
            ...prev,
            Images: Array.from(e.target.files)
        }));
    };

    // const handleSubmit = async (e) => {
    //     e.preventDefault();
    //     const formData = new FormData();
        
    //     // Append basic hotel fields
    //     if (transportForm.Id) {
    //         formData.append("Id", transportForm.Id.toString());
    //     }
    //     formData.append("Title", transportForm.title);
    //     formData.append("Description", transportForm.description);
    //     formData.append("Duration", transportForm.duration);
    //     formData.append("Email", transportForm.email);
    //     formData.append("AdditionalInfo", transportForm.AdditionalInfo || '');
    //     formData.append("Overview", transportForm.Overview || '');
    //     formData.append("TermCondition", transportForm.TermCondition || '');
    //     formData.append("Price", transportForm.price.toString());
        
    //     // Handle image files
    //     if (transportForm.Images && transportForm.Images.length > 0) {
    //         for (let i = 0; i < transportForm.Images.length; i++) {
    //             formData.append("imageFiles", transportForm.Images[i]);
    //         }
    //     }

    // // Log FormData
    // console.log("Logging FormData:");
    // formData.forEach((value, key) => {
    //     console.log(`${key}:`, value instanceof File ? `File: ${value.name}` : value);
    // });
    //     try {
    //         if (transportForm.Id) {
    //             await axios.put(
    //                 `https://api.travoolah.com/api/Hotel/${transportForm.Id}`, 
    //                 formData,
                    
    //             );
    //             alert('Hotel updated successfully!');
    //         } else {
    //             await axios.post(
    //                 'https://api.travoolah.com/api/Hotel', 
    //                 formData,
    //             );
    //             alert('Hotel created successfully!');
    //         }
    //         resetForm();
    //         fetchTravel();
    //     } catch (error) {
    //         console.error("Error submitting form:", error);
    //         alert(`Error: ${error.response?.data || 'Something went wrong'}`);
    //     }
    // };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        
        // Append basic hotel fields
        if (transportForm.Id) {
            formData.append("Id", transportForm.Id.toString());
        }
        formData.append("Title", transportForm.title);
        formData.append("Description", transportForm.description);
        formData.append("Duration", transportForm.duration);
        formData.append("Email", transportForm.email);
        formData.append("AdditionalInfo", transportForm.AdditionalInfo || '');
        formData.append("Overview", transportForm.Overview || '');
        formData.append("TermCondition", transportForm.TermCondition || '');
        formData.append("Price", transportForm.price.toString());
        
        // Handle image files
        if (transportForm.Images && transportForm.Images.length > 0) {
            transportForm.Images.forEach((file) => {
                formData.append("imageFiles", file);
            });
        }
        // Log FormData for debugging
        console.log("Logging FormData:");
        for (let pair of formData.entries()) {
            console.log(`${pair[0]}:`, pair[1] instanceof File ? `File: ${pair[1].name}` : pair[1]);
        }
    
        try {
            if (transportForm.Id) {
                await axios.put(
                    `https://api.travoolah.com/api/Hotel/${transportForm.Id}`, 
                    formData,{
                        headers:{
                            "Content-Type": 'multipart/form-data',
                        }
                    }
                );
                alert('Hotel updated successfully!');
            } else {
                await axios.post(
                    'https://api.travoolah.com/api/Hotel', 
                    formData,{
                        headers:{
                            "Content-Type": 'multipart/form-data',
                        }
                    }
                );
                alert('Hotel created successfully!');
            }
            resetForm();
            fetchTravel();
        } catch (error) {
            console.error("Error submitting form:", error);
            // Attempt to extract and display validation errors
            if (error.response && error.response.data) {
                const errorMessages = [];
                const errors = error.response.data.errors;
                if (errors) {
                    for (const key in errors) {
                        if (errors.hasOwnProperty(key)) {
                            errors[key].forEach((msg) => {
                                errorMessages.push(`${key}: ${msg}`);
                            });
                        }
                    }
                    alert(`Error: \n${errorMessages.join('\n')}`);
                } else {
                    alert(`Error: ${error.response.data.Error || 'Something went wrong'}`);
                }
            } else {
                alert(`Error: ${error.message}`);
            }
        }
    };
    
    const handleEdit = (pkg) => {
        setTransportForm({
            Id: pkg.id,
            title: pkg.title || '',
            description: pkg.description || '',
            duration: pkg.duration || '',
            price: pkg.price || '',
            email: pkg.email || '',
            AdditionalInfo: pkg.additionalInfo || '',
            Overview: pkg.overview || '',
            TermCondition: pkg.termCondition || '',
            Images: []  // Reset images since we can't edit existing ones directly
        });
    };

    const handleDelete = async (id) => {
        if (window.confirm("Are you sure you want to delete this hotel?")) {
            try {
                await axios.delete(`https://api.travoolah.com/api/Hotel/${id}`);
                alert('Hotel deleted successfully!');
                fetchTravel();
            } catch (error) {
                console.error("Error deleting hotel:", error);
                alert(`Error: ${error.response?.data || 'Something went wrong'}`);
            }
        }
    };

    const resetForm = () => {
        setTransportForm({
            Id: null,
            title: '',
            description: '',
            duration: '',
            email: '',
            AdditionalInfo: '',
            Overview: '',
            TermCondition: '',
            price: '',
            Images: []
        });
    };

    return (
        <div className="max-w-4xl mx-auto p-6 bg-white shadow-lg rounded-lg">
            <h2 className="text-2xl font-bold mb-6 text-gray-800">Hotel Management</h2>
            <form onSubmit={handleSubmit} className="space-y-4">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <input 
                        type="text" 
                        name="title" 
                        value={transportForm.title} 
                        onChange={handleChange} 
                        placeholder="Hotel Name" 
                        required 
                        className="w-full p-2 border border-gray-300 rounded-md"
                    />
                    <input 
                        type="text" 
                        name="duration" 
                        value={transportForm.duration} 
                        onChange={handleChange} 
                        placeholder="Hotel Tagline" 
                        required 
                        className="w-full p-2 border border-gray-300 rounded-md"
                    />
                </div>
                <input 
                    type="email" 
                    name="email" 
                    value={transportForm.email} 
                    onChange={handleChange} 
                    placeholder="Contact Email" 
                    required 
                    className="w-full p-2 border border-gray-300 rounded-md"
                />
                <textarea 
                    name="description" 
                    value={transportForm.description} 
                    onChange={handleChange} 
                    placeholder="Hotel Address" 
                    required 
                    className="w-full p-2 border border-gray-300 rounded-md h-24"
                />

                <textarea 
                    name="Overview" 
                    value={transportForm.Overview} 
                    onChange={handleChange} 
                    placeholder="Hotel Description" 
                    className="w-full p-2 border border-gray-300 rounded-md h-24"
                />

                <textarea 
                    name="AdditionalInfo" 
                    value={transportForm.AdditionalInfo} 
                    onChange={handleChange} 
                    placeholder="Additional Information" 
                    className="w-full p-2 border border-gray-300 rounded-md h-24"
                />

                <textarea 
                    name="TermCondition" 
                    value={transportForm.TermCondition} 
                    onChange={handleChange} 
                    placeholder="Terms and Conditions" 
                    className="w-full p-2 border border-gray-300 rounded-md h-24"
                />

                <div className="space-y-2">
                    <label className="block text-sm font-medium text-gray-700">Hotel Images</label>
                    <input 
                        type="file" 
                        multiple 
                        onChange={handleImageChange} 
                        className="block w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-blue-50 file:text-blue-700 hover:file:bg-blue-100"
                    />
                    <span className='text-xs text-gray-500 font-bold'>Max 6 images</span>
                </div>

                <input 
                    type="number" 
                    name="price" 
                    value={transportForm.price} 
                    onChange={handleChange} 
                    placeholder="Price" 
                    required 
                    className="w-full p-2 border border-gray-300 rounded-md"
                />

                <button 
                    type="submit" 
                    className="w-full bg-blue-600 text-white py-2 px-4 rounded-md hover:bg-blue-700 transition duration-300 flex items-center justify-center"
                >
                    <PlusCircle className="mr-2" size={20} />
                    {transportForm.Id ? 'Update Hotel' : 'Add Hotel'}
                </button>
            </form>

            <h3 className="text-xl font-semibold mt-8 mb-4 text-gray-800">Existing Hotels</h3>
            <div className="space-y-4">
                {travelPackages.map(pkg => (
                    <div key={pkg.id} className="flex justify-between items-center p-4 bg-gray-50 rounded-lg">
                        <div>
                            <h4 className="text-lg font-medium text-gray-900">{pkg.title}</h4>
                            <p className="text-sm text-gray-500">{pkg.duration}</p>
                        </div>
                        <div className="flex space-x-2">
                            <button 
                                onClick={() => handleEdit(pkg)} 
                                className="bg-yellow-500 text-white rounded-md p-2 hover:bg-yellow-600 transition duration-300"
                            >
                                <Edit2 size={18} />
                            </button>
                            <button 
                                onClick={() => handleDelete(pkg.id)} 
                                className="bg-red-500 text-white rounded-md p-2 hover:bg-red-600 transition duration-300"
                            >
                                <Trash2 size={18} />
                            </button>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default HotelsAdmin;