import React, { useEffect, useState } from 'react';
import axios from "axios";
import { PlusCircle, Edit2, Trash2 } from 'lucide-react';

function AdventureForm() {
  const [category, setCategory] = useState([]);
  const [travelPackages, setTravelPackages] = useState([]);
  const [transportForm, setTransportForm] = useState({
    Id: null,
    images: [],
    title: '',
    location: '',
    price: '',
    additionalInfo: '',
    overview: '',
    termCondition: '',
    categoryId: ''
  });

  const fetchCategory = async () => {
    try {
      const response = await axios.get('https://api.travoolah.com/api/AdventureCategory');
      setCategory(response.data);
    } catch (e) {
      console.error("error", e);
    }
  };

  const fetchTravel = async () => {
    try {
      const response = await axios.get('https://api.travoolah.com/api/Adventure');
      setTravelPackages(response.data);
    } catch (e) {
      console.error("error", e);
    }
  };

  useEffect(() => {
    fetchCategory();
    fetchTravel();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setTransportForm({ ...transportForm, [name]: value });
  };

  const handleImageChange = (e) => {
    setTransportForm({ ...transportForm, images: Array.from(e.target.files) });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();

    if (transportForm.Id) {
      formData.append("Id", transportForm.Id);
    }
    formData.append("title", transportForm.title);
    formData.append("location", transportForm.location);
    formData.append("price", transportForm.price);
    formData.append("additionalInfo", transportForm.additionalInfo);
    formData.append("overview", transportForm.overview);
    formData.append("termCondition", transportForm.termCondition);
    formData.append("adventureCategoryId", transportForm.categoryId);

    transportForm.images.forEach((image) => {
      formData.append("imageFiles", image);
    });

    try {
      if (transportForm.Id) {
        await axios.put(`https://api.travoolah.com/api/Adventure/${transportForm.Id}`, formData, {
          headers: { 'Content-Type': 'multipart/form-data' }
        });
      } else {
        await axios.post('https://api.travoolah.com/api/Adventure', formData, {
          headers: { 'Content-Type': 'multipart/form-data' }
        });
      }
      resetForm();
      fetchTravel();
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  const handleEdit = (pkg) => {
    setTransportForm({
      Id: pkg.id,
      title: pkg.title,
      location: pkg.location,
      price: pkg.price,
      additionalInfo: pkg.additionalInfo,
      overview: pkg.overview,
      termCondition: pkg.termCondition,
      categoryId: pkg.adventureCategoryId,
      images: [] // Clear images, they will need to be re-uploaded
    });
  };

  const handleDelete = async (id) => {
    if (window.confirm("Are you sure you want to delete this package?")) {
      try {
        await axios.delete(`https://api.travoolah.com/api/Adventure/${id}`);
        fetchTravel();
      } catch (error) {
        console.error("Error deleting package:", error);
      }
    }
  };

  const resetForm = () => {
    setTransportForm({
      Id: null,
      images: [],
      title: '',
      location: '',
      price: '',
      additionalInfo: '',
      overview: '',
      termCondition: '',
      categoryId: ''
    });
  };

  return (
    <div className="max-w-4xl mx-auto p-6 bg-white shadow-lg rounded-lg">
      <h2 className="text-2xl font-bold mb-6 text-gray-800">Adventure Management</h2>
      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <label className="block text-sm font-medium text-gray-700" htmlFor="user_avatar">Upload Images</label>
          <input
            type="file"
            multiple
            accept="image/*"
            onChange={handleImageChange}
            className="mt-1 block w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded-md file:border-0 file:text-sm file:font-semibold file:bg-blue-50 file:text-blue-700 hover:file:bg-blue-100"
          />
          <span className='text-xs text-gray-500 font-bold'>Max 6 images</span>

        </div>
        <input
          type="text"
          name="title"
          value={transportForm.title}
          onChange={handleChange}
          placeholder="Title"
          required
          className="w-full p-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
        />
        <input
          type="text"
          name="location"
          value={transportForm.location}
          onChange={handleChange}
          placeholder="Location"
          required
          className="w-full p-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
        />
        <input
          type="text"
          name="price"
          value={transportForm.price}
          onChange={handleChange}
          placeholder="Price"
          required
          className="w-full p-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
        />
        {/* Corrected the name and value attributes here */}
        <textarea
          name="overview"
          value={transportForm.overview}
          onChange={handleChange}
          placeholder="Description"
          className="w-full p-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
        />
        <textarea
          name="additionalInfo"
          value={transportForm.additionalInfo}
          onChange={handleChange}
          placeholder="Additional Information"
          required
          className="w-full p-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
        />
        <textarea
          name="termCondition"
          value={transportForm.termCondition}
          onChange={handleChange}
          placeholder="Terms & Conditions"
          required
          className="w-full p-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
        />

        <select
          name="categoryId"
          value={transportForm.categoryId}
          onChange={handleChange}
          required
          className="w-full p-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
        >
          <option value="">Select a category</option>
          {category.map(cat => (
            <option key={cat.id} value={cat.id}>{cat.name}</option>
          ))}
        </select>
        <button
          type="submit"
          className="w-full bg-blue-600 text-white py-2 px-4 rounded-md hover:bg-blue-700 transition duration-300 flex items-center justify-center"
        >
          <PlusCircle className="mr-2" size={20} />
          {transportForm.Id ? 'Update Package' : 'Add Package'}
        </button>
      </form>

      <h3 className="text-xl font-semibold mt-8 mb-4 text-gray-800">Existing Adventure Packages</h3>
      <ul className="divide-y divide-gray-200">
        {travelPackages.map(pkg => (
          <li key={pkg.id} className="py-4 flex justify-between items-center">
            <span className="text-lg text-gray-700">{pkg.title}</span>
            <div>
              <button
                onClick={() => handleEdit(pkg)}
                className="bg-yellow-500 text-white rounded-md p-2 mr-2 hover:bg-yellow-600 transition duration-300"
              >
                <Edit2 size={18} />
              </button>
              <button
                onClick={() => handleDelete(pkg.id)}
                className="bg-red-500 text-white rounded-md p-2 hover:bg-red-600 transition duration-300"
              >
                <Trash2 size={18} />
              </button>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default AdventureForm;
