import React, { useState, useEffect } from 'react';
import axios from 'axios';

function ContactForm({ hotelName, hotelId, onSubmit }) {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        contactDate: new Date().toISOString(),
        numberOfChilds: 0,
        numberOfAdults: 0,
        numberOfDays: 0,
        startDate: '',
        endDate: '',
        hotelName: hotelName,
        hotelId: hotelId
    });

    const [errors, setErrors] = useState({});

    // Calculate number of days when dates change
    useEffect(() => {
        if (formData.startDate && formData.endDate) {
            const start = new Date(formData.startDate);
            const end = new Date(formData.endDate);
            const diffTime = Math.abs(end - start);
            const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
            setFormData(prev => ({ ...prev, numberOfDays: diffDays }));
        }
    }, [formData.startDate, formData.endDate]);

    const validateForm = () => {
        const newErrors = {};
        
        // Basic validations
        if (!formData.name.trim()) newErrors.name = 'Name is required';
        if (!formData.email.trim()) newErrors.email = 'Email is required';
        if (!/^\S+@\S+\.\S+$/.test(formData.email)) newErrors.email = 'Invalid email format';
        if (!formData.phone.trim()) newErrors.phone = 'Phone is required';
        
        // Date validations
        const startDate = new Date(formData.startDate);
        const endDate = new Date(formData.endDate);
        const today = new Date();
        today.setHours(0, 0, 0, 0);

        if (!formData.startDate) newErrors.startDate = 'Start date is required';
        if (!formData.endDate) newErrors.endDate = 'End date is required';
        if (startDate < today) newErrors.startDate = 'Start date cannot be in the past';
        if (endDate <= startDate) newErrors.endDate = 'End date must be after start date';

        // Number validations
        if (formData.numberOfAdults < 1) newErrors.numberOfAdults = 'At least one adult is required';
        if (formData.numberOfChilds < 0) newErrors.numberOfChilds = 'Cannot be negative';
        
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({ ...prev, [name]: value }));
        
        // Clear error when field is edited
        if (errors[name]) {
            setErrors(prev => ({ ...prev, [name]: '' }));
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!validateForm()) {
            return;
        }

        // Prepare the data for submission
        const submissionData = {
            ...formData,
            startDate: new Date(formData.startDate).toISOString(),
            endDate: new Date(formData.endDate).toISOString(),
            contactDate: new Date().toISOString()
        };

        try {
            const response = await axios.post('https://api.travoolah.com/api/HotelContact/contact', submissionData);
            onSubmit(response.data);
            alert('Booking inquiry submitted successfully!');
            
            // Reset form
            setFormData({
                name: '',
                email: '',
                phone: '',
                contactDate: new Date().toISOString(),
                numberOfChilds: 0,
                numberOfAdults: 0,
                numberOfDays: 0,
                startDate: '',
                endDate: '',
                hotelName: hotelName,
                hotelId: hotelId
            });
        } catch (error) {
            console.error('Error submitting form:', error);
            alert('Error submitting form. Please try again.');
        }
    };

    return (
        <form onSubmit={handleSubmit} className="space-y-4 max-w-4xl mx-auto px-4 py-6">
            <div className="flex flex-col md:flex-row gap-4">
                <div className="w-full md:w-1/2">
                    <label htmlFor="name" className="block">Name</label>
                    <input
                        type="text"
                        id="name"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        className={`input w-full p-2 border rounded-md ${errors.name ? 'border-red-500' : ''}`}
                    />
                    {errors.name && <p className="text-red-500 text-sm mt-1">{errors.name}</p>}
                </div>

                <div className="w-full md:w-1/2">
                    <label htmlFor="email" className="block">Email</label>
                    <input
                        type="email"
                        id="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        className={`input w-full p-2 border rounded-md ${errors.email ? 'border-red-500' : ''}`}
                    />
                    {errors.email && <p className="text-red-500 text-sm mt-1">{errors.email}</p>}
                </div>
            </div>

            <div className="flex flex-col md:flex-row gap-4">
                <div className="w-full md:w-1/2">
                    <label htmlFor="phone" className="block">Phone</label>
                    <input
                        type="tel"
                        id="phone"
                        name="phone"
                        value={formData.phone}
                        onChange={handleChange}
                        className={`input w-full p-2 border rounded-md ${errors.phone ? 'border-red-500' : ''}`}
                    />
                    {errors.phone && <p className="text-red-500 text-sm mt-1">{errors.phone}</p>}
                </div>

                <div className="w-full md:w-1/2">
                    <label htmlFor="numberOfChilds" className="block">Number of Children</label>
                    <input
                        type="number"
                        id="numberOfChilds"
                        name="numberOfChilds"
                        min="0"
                        value={formData.numberOfChilds}
                        onChange={handleChange}
                        className={`input w-full p-2 border rounded-md ${errors.numberOfChilds ? 'border-red-500' : ''}`}
                    />
                    {errors.numberOfChilds && <p className="text-red-500 text-sm mt-1">{errors.numberOfChilds}</p>}
                </div>
            </div>

            <div className="flex flex-col md:flex-row gap-4">
                <div className="w-full md:w-1/2">
                    <label htmlFor="numberOfAdults" className="block">Number of Adults</label>
                    <input
                        type="number"
                        id="numberOfAdults"
                        name="numberOfAdults"
                        min="1"
                        value={formData.numberOfAdults}
                        onChange={handleChange}
                        className={`input w-full p-2 border rounded-md ${errors.numberOfAdults ? 'border-red-500' : ''}`}
                    />
                    {errors.numberOfAdults && <p className="text-red-500 text-sm mt-1">{errors.numberOfAdults}</p>}
                </div>

                <div className="w-full md:w-1/2">
                    <label htmlFor="numberOfDays" className="block">Number of Days</label>
                    <input
                        type="number"
                        id="numberOfDays"
                        name="numberOfDays"
                        value={formData.numberOfDays}
                        readOnly
                        className="input w-full p-2 border rounded-md bg-gray-100"
                    />
                </div>
            </div>

            <div className="flex flex-col md:flex-row gap-4">
                <div className="w-full md:w-1/2">
                    <label htmlFor="startDate" className="block">Start Date</label>
                    <input
                        type="date"
                        id="startDate"
                        name="startDate"
                        value={formData.startDate}
                        min={new Date().toISOString().split('T')[0]}
                        onChange={handleChange}
                        className={`input w-full p-2 border rounded-md ${errors.startDate ? 'border-red-500' : ''}`}
                    />
                    {errors.startDate && <p className="text-red-500 text-sm mt-1">{errors.startDate}</p>}
                </div>

                <div className="w-full md:w-1/2">
                    <label htmlFor="endDate" className="block">End Date</label>
                    <input
                        type="date"
                        id="endDate"
                        name="endDate"
                        value={formData.endDate}
                        min={formData.startDate || new Date().toISOString().split('T')[0]}
                        onChange={handleChange}
                        className={`input w-full p-2 border rounded-md ${errors.endDate ? 'border-red-500' : ''}`}
                    />
                    {errors.endDate && <p className="text-red-500 text-sm mt-1">{errors.endDate}</p>}
                </div>
            </div>

            <button 
                type="submit" 
                className="btn w-full md:w-auto bg-blue-500 text-white p-2 rounded-md hover:bg-blue-600 mt-4"
            >
                Submit Booking Inquiry
            </button>
        </form>
    );
}

export default ContactForm;