import React, { useEffect, useState } from 'react';
import axios from "axios";
import { Plus, Trash2 } from 'lucide-react';

function PackageInclude() {
  const [eventManagements, setEventManagements] = useState([]);
  const [selectedEventId, setSelectedEventId] = useState('');
  const [packageIncludes, setPackageIncludes] = useState([]);
  const [newInclude, setNewInclude] = useState({
    title: '',
    description: '',
    icon: ''
  });

  useEffect(() => {
    fetchEventManagements();
  }, []);

  useEffect(() => {
    if (selectedEventId) {
      fetchPackageIncludes();
    } else {
      setPackageIncludes([]);
    }
  }, [selectedEventId]);

  const fetchEventManagements = async () => {
    try {
      const response = await axios.get('https://api.travoolah.com/api/EventManagement');
      setEventManagements(response.data);
    } catch (error) {
      console.error("Error fetching event management packages:", error);
    }
  };

  const fetchPackageIncludes = async () => {
    try {
      const response = await axios.get(`https://api.travoolah.com/api/EventmanagementPackages?eventManagementId=${selectedEventId}`);
      setPackageIncludes(response.data);
    } catch (error) {
      console.error("Error fetching package includes:", error);
    }
  };

  const handleAddInclude = async () => {
    if (!selectedEventId) {
      alert("Please select an event management package first.");
      return;
    }
    const payload = {
      title: newInclude.title,
      description: newInclude.description,
      icon: newInclude.icon,
      eventManagementId: parseInt(selectedEventId)
    };
    try {
      const response = await axios.post('https://api.travoolah.com/api/EventmanagementPackages', payload);
      setPackageIncludes([...packageIncludes, response.data]);
      setNewInclude({ title: '', description: '', icon: '' });
    } catch (error) {
      console.error("Error adding package include:", error);
    }
  };

  const handleDeleteInclude = async (id) => {
    if (window.confirm("Are you sure you want to delete this package include?")) {
      try {
        await axios.delete(`https://api.travoolah.com/api/EventmanagementPackages/${id}`);
        setPackageIncludes(packageIncludes.filter(pi => pi.id !== id));
      } catch (error) {
        console.error("Error deleting package include:", error);
      }
    }
  };

  const handleEventSelect = (e) => {
    setSelectedEventId(e.target.value);
  };

  return (
    <div className="max-w-3xl mx-auto p-6 bg-white shadow-lg rounded-lg">
      <h2 className="text-2xl font-bold mb-6 text-gray-800">Manage Package Includes</h2>

      {/* Event Selection Dropdown */}
      <div className="mb-6">
        <label htmlFor="eventManagementId" className="block text-gray-700 font-medium mb-2">
          Select Event Management Package:
        </label>
        <select
          id="eventManagementId"
          value={selectedEventId}
          onChange={handleEventSelect}
          className="w-full p-2 border border-gray-300 rounded-md"
        >
          <option value="">-- Select an Event Management Package --</option>
          {eventManagements.map(event => (
            <option key={event.id} value={event.id}>
              {event.title}
            </option>
          ))}
        </select>
      </div>

      {selectedEventId && (
        <>
          <div className="mb-6">
            <h3 className="text-lg font-semibold mb-3">Add New Include</h3>
            <input
              type="text"
              value={newInclude.title}
              onChange={(e) => setNewInclude({ ...newInclude, title: e.target.value })}
              placeholder="Title"
              className="w-full p-2 border border-gray-300 rounded-md mb-2"
            />
            <textarea
              value={newInclude.description}
              onChange={(e) => setNewInclude({ ...newInclude, description: e.target.value })}
              placeholder="Description"
              className="w-full p-2 border border-gray-300 rounded-md mb-2"
            />
            <input
              type="text"
              value={newInclude.icon}
              onChange={(e) => setNewInclude({ ...newInclude, icon: e.target.value })}
              placeholder="Icon URL or Name"
              className="w-full p-2 border border-gray-300 rounded-md mb-2"
            />
            <button
              onClick={handleAddInclude}
              className="p-2 bg-green-500 text-white rounded-md hover:bg-green-600 flex items-center"
            >
              <Plus size={20} className="mr-1" />
              Add Include
            </button>
          </div>
          <h3 className="text-lg font-semibold mb-3">Existing Includes</h3>
          <ul className="divide-y divide-gray-200">
            {packageIncludes.map((include) => (
              <li key={include.id} className="py-4 flex justify-between items-center">
                <div>
                  <p className="font-medium">{include.title}</p>
                  <p className="text-sm text-gray-600">{include.description}</p>
                  <p className="text-sm text-gray-600">Icon: {include.icon}</p>
                </div>
                <button
                  onClick={() => handleDeleteInclude(include.id)}
                  className="bg-red-500 text-white rounded-md p-2 hover:bg-red-600 transition duration-300"
                >
                  <Trash2 size={18} />
                </button>
              </li>
            ))}
          </ul>
        </>
      )}
    </div>
  );
}


export default PackageInclude