import React, { useState } from 'react';
import axios from 'axios';

// normal contact for rest of the website
function ContactForm({ hotelId, hotelName, onSuccess }) {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    contactDate: new Date().toISOString(),
    numberOfAdults: 1,
    numberOfChilds: 0,
    numberOfDays: 1,
    hotelId,
    hotelName,
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('https://api.travoolah.com/api/Contact', formData);
      if (response.status === 201) {
        alert('Your booking request has been sent successfully!');
        onSuccess(); // Call onSuccess function passed as prop
      }
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };

  return (
    <div className="mt-8 p-6 bg-white shadow-md rounded">
      <h2 className="text-2xl font-semibold text-center">Contact Form</h2>
      <form onSubmit={handleFormSubmit}>
        {/* Name */}
        <div className="mt-4">
          <label className="block font-semibold">Name</label>
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleInputChange}
            className="w-full p-2 mt-2 border border-gray-300 rounded"
            required
          />
        </div>

        {/* Email */}
        <div className="mt-4">
          <label className="block font-semibold">Email</label>
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleInputChange}
            className="w-full p-2 mt-2 border border-gray-300 rounded"
            required
          />
        </div>

        {/* Phone */}
        <div className="mt-4">
          <label className="block font-semibold">Phone</label>
          <input
            type="text"
            name="phone"
            value={formData.phone}
            onChange={handleInputChange}
            className="w-full p-2 mt-2 border border-gray-300 rounded"
            required
          />
        </div>

        {/* Number of Adults */}
        <div className="mt-4">
          <label className="block font-semibold">Number of Adults</label>
          <input
            type="number"
            name="numberOfAdults"
            value={formData.numberOfAdults}
            onChange={handleInputChange}
            className="w-full p-2 mt-2 border border-gray-300 rounded"
            min="1"
            required
          />
        </div>

        {/* Number of Children */}
        <div className="mt-4">
          <label className="block font-semibold">Number of Children</label>
          <input
            type="number"
            name="numberOfChilds"
            value={formData.numberOfChilds}
            onChange={handleInputChange}
            className="w-full p-2 mt-2 border border-gray-300 rounded"
            min="0"
          />
        </div>

        {/* Number of Days */}
        <div className="mt-4">
          <label className="block font-semibold">Number of Days</label>
          <input
            type="number"
            name="numberOfDays"
            value={formData.numberOfDays}
            onChange={handleInputChange}
            className="w-full p-2 mt-2 border border-gray-300 rounded"
            min="1"
            required
          />
        </div>

        {/* Submit Button */}
        <div className="mt-6 text-center">
          <button type="submit" className="bg-green-500 text-white py-2 px-6 rounded">
            Submit Booking Request
          </button>
        </div>
      </form>
    </div>
  );
}

export default ContactForm;
