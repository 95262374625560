import React from 'react'
import letsTravel from "../../Assets/letsTravel.jpg"
import logo from "../../Assets/logo.svg"
import iphone from "../../Assets/iphone.svg"
import leafImage from "../../Assets/leaf.svg"
import leaf2 from "../../Assets/leaf2.svg"
import leaf3 from "../../Assets/leaf3.svg"
function LetsTravel() {
  return (
    <div className="hidden lg:flex relative h-screen  items-center justify-center bg-cover bg-center" style={{ backgroundImage: `url(${letsTravel})` }}>
        {/* <img src={letsTravel} alt='traveImg' className='w-full'/> */}
        
      <div className="relative z-10 text-left text-white flex flex-col">
        <h1 className="text-8xl py-4 px-3 font-bold">LET'S</h1>
        <h1 className="text-8xl py-4 px-3 font-bold">TRAVEL</h1>
        
        {/* <button className="mt-4 bg-orange-500 hover:bg-orange-600 text-white font-bold py-2 px-5 rounded">
          Contact Us
        </button> */}
      </div>
      {/* Phone with Screen Image */}
      <div className="relative z-10 mt-10">
        <img src={iphone} alt="Phone Frame" className="h-[500px] mx-auto" />
        {/* Phone Frame */}
        <div className='absolute  top-[15%] left-[60%]'>
          <img src={logo} alt='logo' className="mx-auto w-32"/>
        </div>
      </div>
        
        {/* Leaf Overlays */}
      <img
        src={leafImage}
        alt="Leaf"
        className="absolute top-1/4 left-[76%] h-30 w-30"
      />
      <img
        src={leaf2}
        alt="Leaf"
        className="absolute top-[69%] left-[59%] h-30 w-30 z-20"
      />
      <img
        src={leaf3}
        alt="Leaf"
        className="absolute top-[35%] left-[27%] h-30 w-30 z-20"
      />
    </div>

  )
}

export default LetsTravel