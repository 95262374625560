import React, {useEffect , useState} from 'react'
import { Link } from "react-router-dom";

function LeftNav() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem('jwtToken');
    setIsLoggedIn(!!token);
  }, []);

  const handleLogout = () => {
    localStorage.removeItem('jwtToken');
    setIsLoggedIn(false);
    window.location.href = '/admin/login';
  };

    return (
        <div className="col-span-1 w-full flex flex-col items-center bg-gradient-to-r from-[#EAAC83] to-[#E06914] hover:transition-all hover:duration-300">
          <div className="flex justify-center items-center py-5 gap-x-4 text-xl">
            <div className="text-[#DDDDDD] text-xl">
              <p>Admin</p>
              <p>Welcome Back!</p>
            </div>
          </div>
          <div>
            <ul  className="gap-y-4 flex flex-col px-2 py-10 group text-white">
            {/* newRoutes */}
             <li className="hover:text-black cursor-pointer hover:transition-all  duration-500 ease-in-out hover:translate-x-2">
                <Link to="/admin/hotels">
                  <span className="flex items-center gap-2 text-xl px-2 py-4 rounded-l-lg">
                    Hotels
                  </span>
                </Link>
              </li>

              {/* service admins */}
              <li className="hover:text-black cursor-pointer hover:transition-all  duration-500 ease-in-out hover:translate-x-2">
                <Link to="/admin/hotelservices">
                  <span className="flex items-center gap-2 text-xl px-2 py-4 rounded-l-lg">
                    Add Hotel Services
                  </span>
                </Link>
              </li>

              <li className="hover:text-black cursor-pointer hover:transition-all  duration-500 ease-in-out hover:translate-x-2">
                <Link to="/admin/villas">
                  <span className="flex items-center gap-2 text-xl px-2 py-4 rounded-l-lg">
                    Villas
                  </span>
                </Link>
              </li>

              {/* rest */}
              <li className="hover:text-black cursor-pointer hover:transition-all  duration-500 ease-in-out hover:translate-x-2">
                <Link to="/admin/travel">
                  <span className="flex items-center gap-2 text-xl px-2 py-4 rounded-l-lg">
                    Travel Package
                  </span>
                </Link>
              </li>

              <li className="hover:text-black cursor-pointer hover:transition-all  duration-500 ease-in-out hover:translate-x-2">
                <Link to="/admin/itenaries">
                  <span className="flex items-center gap-2 text-xl px-2 py-4 rounded-l-lg">
                    Travelpackage Itenaries
                  </span>
                </Link>
              </li>

              <li className="hover:text-black cursor-pointer hover:transition-all  duration-500 ease-in-out hover:translate-x-2">
                <Link to="/admin/transport">
                  <span className="flex items-center gap-2 text-xl px-2 py-4 rounded-l-lg">
                    Transportation
                  </span>
                </Link>
              </li>

              <li className="hover:text-black cursor-pointer hover:transition-all  duration-500 ease-in-out hover:translate-x-2">
                <Link to="/admin/shoots">
                  <span className="flex items-center gap-2 text-xl px-2 py-4 rounded-l-lg">
                    Shoots
                  </span>
                </Link>
              </li>

              <li className="hover:text-black cursor-pointer hover:transition-all  duration-500 ease-in-out hover:translate-x-2">
                <Link to="/admin/adventure">
                  <span className="flex items-center gap-2 text-xl px-2 py-4 rounded-l-lg">
                    Adventure
                  </span>
                </Link>
              </li>
              
              <li className="hover:text-black cursor-pointer hover:transition-all  duration-500 ease-in-out hover:translate-x-2">
                <Link to="/admin/event">
                  <span className="flex items-center gap-2 text-xl px-2 py-4 rounded-l-lg">
                    Event Managament
                  </span>
                </Link>
              </li>

              
              <li className="hover:text-black cursor-pointer hover:transition-all  duration-500 ease-in-out hover:translate-x-2">
                <Link to="/admin/addons">
                  <span className="flex items-center gap-2 text-xl px-2 py-4 rounded-l-lg">
                    Add EventAddons
                  </span>
                </Link>
              </li>

              <li className="hover:text-black cursor-pointer hover:transition-all  duration-500 ease-in-out hover:translate-x-2">
                <Link to="/admin/eventDayWize">
                  <span className="flex items-center gap-2 text-xl px-2 py-4 rounded-l-lg">
                    Add Eventitenarise
                  </span>
                </Link>
              </li>

              
              <li className="hover:text-black cursor-pointer hover:transition-all  duration-500 ease-in-out hover:translate-x-2">
                <Link to="/admin/packageInclude">
                  <span className="flex items-center gap-2 text-xl px-2 py-4 rounded-l-lg">
                    Add Eventincludes
                  </span>
                </Link>
              </li>

              <li className="hover:text-black cursor-pointer hover:transition-all  duration-500 ease-in-out hover:translate-x-2">
                <Link to="/admin/wellness">
                  <span className="flex items-center gap-2 text-xl px-2 py-4 rounded-l-lg">
                    Wellness
                  </span>
                </Link>
              </li>

              <li className="hover:text-black cursor-pointer hover:transition-all  duration-500 ease-in-out hover:translate-x-2">
                <Link to="/admin/travelAi">
                  <span className="flex items-center gap-2 text-xl px-2 py-4 rounded-l-lg">
                    Travel Ai
                  </span>
                </Link>
              </li>
              
        {/* Logout Button */}
        {isLoggedIn && (
          <button 
            onClick={handleLogout} 
            className="hidden lg:block bg-red-500 text-white rounded hover:bg-red-600"
          >
            Logout
          </button>
        )}

            </ul>
          </div>

        </div>
      );
}

export default LeftNav