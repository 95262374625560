import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import ContactForm from './HotelContact'; // Import the ContactForm component

function HotelDetailsPage() {
  const { id } = useParams();
  const [hotel, setHotel] = useState(null);
  const [showForm, setShowForm] = useState(false); // State to toggle the form visibility

  // Fetch hotel details (including services)
  useEffect(() => {
    const fetchHotelData = async () => {
      try {
        const response = await axios.get(`https://api.travoolah.com/api/Hotel/${id}`);
        setHotel(response.data);
      } catch (error) {
        console.error("Error fetching hotel details:", error);
      }
    };

    fetchHotelData();
  }, [id]);

  if (!hotel) return <p>Loading...</p>;

  const handleFormSubmit = (data) => {
    console.log("Form submitted:", data);
    alert('Form submitted successfully!');
  };

  const handleBookNowClick = () => {
    setShowForm(true); // Show the form when the button is clicked
  };

  return (
    <div className="max-w-7xl mx-auto px-4 mt-20 py-7">
      {/* Header Section */}
      <header className="text-center">
        <h1 className="text-4xl font-bold mb-4">{hotel.title}</h1>
        <button
          onClick={handleBookNowClick}
          className="bg-orange-500 text-white py-2 px-6 rounded hover:bg-orange-600">
          Book Now
        </button>
      </header>

      {/* Image Gallery */}
      <section className="mt-8">
        <div className="grid grid-cols-2 sm:grid-cols-3 gap-4">
          {hotel.images && hotel.images.length > 0 ? (
            hotel.images.map((image, index) => {
              const imageClasses = [
                "col-span-2 row-span-2", // Larger image for img[0]
                "col-span-1 row-span-1", // Normal size for img[1]
                "col-span-1 row-span-1", // Normal size for img[2]
                "col-span-1 row-span-2", // Taller image for img[3]
                "col-span-1 row-span-1", // Normal size for img[4]
              ];
              return (
                <img
                  key={index}
                  src={`https://api.travoolah.com/${image}`}
                  alt={`Hotel Image ${index + 1}`}
                  className={`w-full h-full object-cover rounded ${imageClasses[index] || ''}`}
                />
              );
            })
          ) : (
            <p className="text-center text-gray-500 col-span-3">No images available</p>
          )}
        </div>
      </section>


      {/* Description Section */}
      <section className="mt-10">
        {hotel.duration && (
          <p className="text-center mt-4 text-lg font-semibold">{hotel.duration}</p>
        )}
        {hotel.overview && (
          <div className='flex flex-col py-5'>
            <p className='text-center text-black text-xl font-bold'>Description</p>
            <p className="text-center text-gray-700">{hotel.overview}</p>
          </div>
        )}
        {hotel.price && (
          <p className="text-center text-lg font-semibold">Price: ₹{hotel.price} <span className='text-gray-500 text-xs'>/Per Night</span></p>
        )}
      </section>

      {/* Services Section */}
      {hotel.services && hotel.services.length > 0 && (
        <section className="mt-12">
          <h2 className="text-3xl font-semibold text-center">Services</h2>
          <div className="grid grid-cols-2 md:grid-cols-4 gap-6 mt-6">
            {hotel.services.map((service, index) => (
              <div key={index} className="text-center border rounded-md px-3 py-6">
                <p className="mt-2 text-gray-700 font-bold">{service.title}</p>
                <p className="text-gray-500 text-sm">{service.description}</p>
              </div>
            ))}
          </div>
        </section>
      )}

      {/* Additional Information Section */}
      {hotel.additionalInfo && hotel.additionalInfo.trim().length > 0 && (
        <section className="mt-12">
          <h2 className="text-3xl font-semibold text-center">Additional Information</h2>
          <div className="mt-6 text-center">
            <p className="text-gray-700">{hotel.additionalInfo}</p>
          </div>
        </section>
      )}

      {/* terms and contidioins */}

      {
        hotel.termCondition && hotel.termCondition.trim().length > 0 && (
          <section className="mt-12">
            <h2 className="text-3xl font-semibold text-center">Terms & Conditions</h2>
            <div className="mt-6 text-center">
              <p className="text-gray-700">{hotel.termCondition}</p>
            </div>
          </section>
        )
      }


      {/* Show the Contact Form when the Book Now button is clicked */}
      {showForm && (
        <section className="mt-12">
          <h2 className="text-3xl font-semibold text-center">Contact Form</h2>
          <ContactForm hotelName={hotel.title} hotelId={hotel.id} onSubmit={handleFormSubmit} />
        </section>
      )}
    </div>
  );
}

export default HotelDetailsPage;
