import React, { useEffect, useState } from 'react';
import axios from "axios";
import { PlusCircle, Edit2, Trash2 } from 'lucide-react';

function VillasAdmin() {
  const [villas, setVillas] = useState([]);
  const [villaForm, setVillaForm] = useState({
    Id: null,
    title: '',
    images: [],
    description: '',
    duration: '',
    additionalInfo: '',
    overview: '',
    termCondition: '',
    price: ''
  });

  // Fetch Villas
  const fetchVillas = async () => {
    try {
      const response = await axios.get('https://api.travoolah.com/api/Villa');
      setVillas(response.data);
    } catch (e) {
      console.error("Error fetching villas:", e);
    }
  };

  useEffect(() => {
    fetchVillas();
  }, []);

  // Handle Input Changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setVillaForm({ ...villaForm, [name]: value });
  };

  // Handle Image Upload
  const handleImageChange = (e) => {
    setVillaForm({ ...villaForm, images: Array.from(e.target.files) });
  };

  // Handle Form Submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();

    // Append Villa Details
    if (villaForm.Id) formData.append("Id", villaForm.Id);
    formData.append("Title", villaForm.title);
    formData.append("Description", villaForm.description);
    formData.append("Duration", villaForm.duration);
    formData.append("AdditionalInfo", villaForm.additionalInfo);
    formData.append("Overview", villaForm.overview);
    formData.append("TermCondition", villaForm.termCondition);
    formData.append("Price", villaForm.price);

    // Append Images
    villaForm.images.forEach((image) => {
      formData.append("imageFiles", image);
    });

    try {
      if (villaForm.Id) {
        // Update Existing Villa
        await axios.put(`https://api.travoolah.com/api/Villa/${villaForm.Id}`, formData, {
          headers: { 'Content-Type': 'multipart/form-data' }
        });
      } else {
        // Add New Villa
        await axios.post('https://api.travoolah.com/api/Villa', formData, {
          headers: { 'Content-Type': 'multipart/form-data' }
        });
      }
      resetForm();
      fetchVillas();
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  // Handle Editing a Villa
  const handleEdit = (villa) => {
    setVillaForm({
      Id: villa.id,
      title: villa.title,
      description: villa.description,
      duration: villa.duration,
      additionalInfo: villa.additionalInfo || "",
      overview: villa.overview || "",
      termCondition: villa.termCondition || "",
      price: villa.price,
      images: [] // Reset images to avoid re-uploading
    });
  };

  // Handle Deleting a Villa
  const handleDelete = async (id) => {
    if (window.confirm("Are you sure you want to delete this villa?")) {
      try {
        await axios.delete(`https://api.travoolah.com/api/Villa/${id}`);
        fetchVillas();
      } catch (error) {
        console.error("Error deleting villa:", error);
      }
    }
  };

  // Reset Form
  const resetForm = () => {
    setVillaForm({
      Id: null,
      title: '',
      images: [],
      description: '',
      duration: '',
      additionalInfo: '',
      overview: '',
      termCondition: '',
      price: ''
    });
  };

  return (
    <div className="max-w-4xl mx-auto p-6 bg-white shadow-lg rounded-lg">
      <h2 className="text-2xl font-bold mb-6 text-gray-800">Villas Management</h2>
      <form onSubmit={handleSubmit} className="space-y-4">
        <input type="text" name="title" value={villaForm.title} onChange={handleChange} placeholder="Villa Title" required className="w-full p-2 border border-gray-300 rounded-md" />
        <input type="text" name="duration" value={villaForm.duration} onChange={handleChange} placeholder="Villa tagline" required className="w-full p-2 border border-gray-300 rounded-md" />
        <textarea name="description" value={villaForm.description} onChange={handleChange} placeholder="Address" required className="w-full p-2 border border-gray-300 rounded-md h-24" />
        <textarea name="overview" value={villaForm.overview} onChange={handleChange} placeholder="Descripion" className="w-full p-2 border border-gray-300 rounded-md h-24" />
        <textarea name="additionalInfo" value={villaForm.additionalInfo} onChange={handleChange} placeholder="Additional Info" className="w-full p-2 border border-gray-300 rounded-md h-24" />
        <textarea name="termCondition" value={villaForm.termCondition} onChange={handleChange} placeholder="Terms and Conditions" className="w-full p-2 border border-gray-300 rounded-md h-24" />
        <input type="text" name="price" value={villaForm.price} onChange={handleChange} placeholder="Price" required className="w-full p-2 border border-gray-300 rounded-md" />
        <input type="file" multiple onChange={handleImageChange} className="block w-full text-sm text-gray-500 mt-2" />
        <span className='text-xs text-gray-500 font-bold'>Max 6 images</span>
        <button type="submit" className="w-full bg-blue-600 text-white py-2 px-4 rounded-md hover:bg-blue-700 transition duration-300 flex items-center justify-center">
          <PlusCircle className="mr-2" size={20} />
          {villaForm.Id ? 'Update Villa' : 'Add Villa'}
        </button>
      </form>

      <h3 className="text-xl font-semibold mt-8 mb-4 text-gray-800">Existing Villas</h3>
      <ul className="divide-y divide-gray-200">
        {villas.map((villa) => (
          <li key={villa.id} className="py-4 flex justify-between items-center">
            <span className="text-lg text-gray-700">{villa.title}</span>
            <div>
              <button onClick={() => handleEdit(villa)} className="bg-yellow-500 text-white rounded-md p-2 mr-2 hover:bg-yellow-600 transition duration-300">
                <Edit2 size={18} />
              </button>
              <button onClick={() => handleDelete(villa.id)} className="bg-red-500 text-white rounded-md p-2 hover:bg-red-600 transition duration-300">
                <Trash2 size={18} />
              </button>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default VillasAdmin;
