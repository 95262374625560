import React, { useEffect, useState } from 'react';
import axios from "axios";
import { PlusCircle, Edit2, Trash2, Plus, Minus } from 'lucide-react';

const TravelPackageForm = () => {
  const [categories, setCategories] = useState([]);
  const [travelPackages, setTravelPackages] = useState([]);
  const [formData, setFormData] = useState({
    id: null,
    title: '',
    duration: '',
    description: '',
    overview: '',
    price: '',
    travelPackageCategoryId: '',
    images: [],
    inclusions: [''],
    exclusions: [''],
    paymentTerms: '',
    cancellationPolicy: '',
  });

  const fetchCategories = async () => {
    try {
      const response = await axios.get('https://api.travoolah.com/api/TravelPackageCategory');
      setCategories(response.data);
    } catch (e) {
      console.error("Error fetching categories:", e);
    }
  };

  const fetchTravelPackages = async () => {
    try {
      const response = await axios.get('https://api.travoolah.com/api/TravelPackage');
      setTravelPackages(response.data);
    } catch (e) {
      console.error("Error fetching travel packages:", e);
    }
  };

  useEffect(() => {
    fetchCategories();
    fetchTravelPackages();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const handleImageChange = (e) => {
    setFormData(prev => ({ ...prev, images: Array.from(e.target.files) }));
  };

  const handleInclusionChange = (index, value) => {
    const newInclusions = [...formData.inclusions];
    newInclusions[index] = value;
    setFormData(prev => ({ ...prev, inclusions: newInclusions }));
  };

  const handleExclusionChange = (index, value) => {
    const newExclusions = [...formData.exclusions];
    newExclusions[index] = value;
    setFormData(prev => ({ ...prev, exclusions: newExclusions }));
  };

  const addInclusion = () => {
    setFormData(prev => ({ ...prev, inclusions: [...prev.inclusions, ''] }));
  };

  const addExclusion = () => {
    setFormData(prev => ({ ...prev, exclusions: [...prev.exclusions, ''] }));
  };

  const removeInclusion = (index) => {
    const newInclusions = formData.inclusions.filter((_, i) => i !== index);
    setFormData(prev => ({ ...prev, inclusions: newInclusions }));
  };

  const removeExclusion = (index) => {
    const newExclusions = formData.exclusions.filter((_, i) => i !== index);
    setFormData(prev => ({ ...prev, exclusions: newExclusions }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const submitData = new FormData();

    // Append all the basic fields
    if (formData.id) {
      submitData.append("Id", formData.id);
    }
    submitData.append("Title", formData.title);
    submitData.append("Duration", formData.duration);
    submitData.append("Description", formData.description);
    submitData.append("Overview", formData.overview);
    submitData.append("Price", formData.price);
    submitData.append("TravelPackageCategoryId", formData.travelPackageCategoryId);
    submitData.append("PaymentTerms", formData.paymentTerms);
    submitData.append("CancellationPolicy", formData.cancellationPolicy);

    // Handle multiple images
    formData.images.forEach(image => {
      submitData.append("imageFiles", image);
    });

    // Handle inclusions and exclusions
    formData.inclusions.forEach((inclusion, index) => {
      submitData.append(`Inclusions[${index}]`, inclusion);
    });

    formData.exclusions.forEach((exclusion, index) => {
      submitData.append(`Exclusions[${index}]`, exclusion);
    });

    try {
      if (formData.id) {
        await axios.put(`https://api.travoolah.com/api/TravelPackage/${formData.id}`, submitData, {
          headers: { 'Content-Type': 'multipart/form-data' }
        });
      } else {
        await axios.post('https://api.travoolah.com/api/TravelPackage', submitData, {
          headers: { 'Content-Type': 'multipart/form-data' }
        });
      }
      resetForm();
      fetchTravelPackages();
    } catch (error) {
      console.error("Error submitting form:", error);
      if (error.response) {
        console.error("Error response:", error.response.data);
        alert(`Error: ${JSON.stringify(error.response.data)}`);
      }
    }
  };

  const handleEdit = async (pkg) => {
    setFormData({
      id: pkg.id,
      title: pkg.title || '',
      duration: pkg.duration || '',
      description: pkg.description || '',
      overview: pkg.overview || '',
      price: pkg.price || '',
      travelPackageCategoryId: pkg.travelPackageCategoryId || '',
      images: [],
      inclusions: pkg.inclusions || [''],
      exclusions: pkg.exclusions || [''],
      paymentTerms: pkg.paymentTerms || '',
      cancellationPolicy: pkg.cancellationPolicy || '',
    });
  };

  const handleDelete = async (id) => {
    if (window.confirm("Are you sure you want to delete this package?")) {
      try {
        await axios.delete(`https://api.travoolah.com/api/TravelPackage/${id}`);
        fetchTravelPackages();
      } catch (error) {
        console.error("Error deleting package:", error);
      }
    }
  };

  const resetForm = () => {
    setFormData({
      id: null,
      title: '',
      duration: '',
      description: '',
      overview: '',
      price: '',
      travelPackageCategoryId: '',
      images: [],
      inclusions: [''],
      exclusions: [''],
      paymentTerms: '',
      cancellationPolicy: '',
    });
  };

  return (
    <div className="max-w-4xl mx-auto p-6 bg-white shadow-lg rounded-lg">
      <h2 className="text-2xl font-bold mb-6 text-gray-800">Travel Package Form</h2>
      
      <form onSubmit={handleSubmit} className="space-y-4">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          {/* Basic Information */}
          <div className="md:col-span-2">
            <h3 className="text-lg font-semibold mb-3">Basic Information</h3>
            <input
              type="file"
              multiple
              onChange={handleImageChange}
              className="w-full p-2 border border-gray-300 rounded-md"
              accept="image/*"
            />
            <span className='text-xs text-gray-500 font-bold'>Max 6 images</span>
          </div>

          <input
            type="text"
            name="title"
            value={formData.title}
            onChange={handleChange}
            placeholder="Title"
            className="w-full p-2 border border-gray-300 rounded-md"
            required
          />

          <input
            type="text"
            name="duration"
            value={formData.duration}
            onChange={handleChange}
            placeholder="Duration"
            className="w-full p-2 border border-gray-300 rounded-md"
          />

          <div className="md:col-span-2">
            <textarea
              name="description"
              value={formData.description}
              onChange={handleChange}
              placeholder="From where to where"
              className="w-full p-2 border border-gray-300 rounded-md h-24"
            />
          </div>

          <div className="md:col-span-2">
            <textarea
              name="overview"
              value={formData.overview}
              onChange={handleChange}
              placeholder="Description"
              className="w-full p-2 border border-gray-300 rounded-md h-24"
            />
          </div>

          <input
            type="number"
            name="price"
            value={formData.price}
            onChange={handleChange}
            placeholder="Price"
            className="w-full p-2 border border-gray-300 rounded-md"
            required
          />

          <select
            name="travelPackageCategoryId"
            value={formData.travelPackageCategoryId}
            onChange={handleChange}
            className="w-full p-2 border border-gray-300 rounded-md"
            required
          >
            <option value="">Select Category</option>
            {categories.map(cat => (
              <option key={cat.id} value={cat.id}>{cat.name}</option>
            ))}
          </select>

          {/* Inclusions */}
          <div className="md:col-span-2">
            <h3 className="text-lg font-semibold mb-3">Inclusions</h3>
            {formData.inclusions.map((inclusion, index) => (
              <div key={index} className="flex gap-2 mb-2">
                <input
                  type="text"
                  value={inclusion}
                  onChange={(e) => handleInclusionChange(index, e.target.value)}
                  placeholder="Inclusion item"
                  className="flex-1 p-2 border border-gray-300 rounded-md"
                />
                <button
                  type="button"
                  onClick={() => removeInclusion(index)}
                  className="p-2 bg-red-500 text-white rounded-md hover:bg-red-600"
                >
                  <Minus size={20} />
                </button>
              </div>
            ))}
            <button
              type="button"
              onClick={addInclusion}
              className="mt-2 p-2 bg-green-500 text-white rounded-md hover:bg-green-600"
            >
              <Plus size={20} />
            </button>
          </div>

          {/* Exclusions */}
          <div className="md:col-span-2">
            <h3 className="text-lg font-semibold mb-3">Exclusions</h3>
            {formData.exclusions.map((exclusion, index) => (
              <div key={index} className="flex gap-2 mb-2">
                <input
                  type="text"
                  value={exclusion}
                  onChange={(e) => handleExclusionChange(index, e.target.value)}
                  placeholder="Exclusion item"
                  className="flex-1 p-2 border border-gray-300 rounded-md"
                />
                <button
                  type="button"
                  onClick={() => removeExclusion(index)}
                  className="p-2 bg-red-500 text-white rounded-md hover:bg-red-600"
                >
                  <Minus size={20} />
                </button>
              </div>
            ))}
            <button
              type="button"
              onClick={addExclusion}
              className="mt-2 p-2 bg-green-500 text-white rounded-md hover:bg-green-600"
            >
              <Plus size={20} />
            </button>
          </div>

          {/* Terms and Policies */}
          <div className="md:col-span-2">
            <h3 className="text-lg font-semibold mb-3">Terms and Policies</h3>
            <textarea
              name="paymentTerms"
              value={formData.paymentTerms}
              onChange={handleChange}
              placeholder="Payment Terms"
              className="w-full p-2 border border-gray-300 rounded-md h-24 mb-4"
            />
            <textarea
              name="cancellationPolicy"
              value={formData.cancellationPolicy}
              onChange={handleChange}
              placeholder="Cancellation Policy"
              className="w-full p-2 border border-gray-300 rounded-md h-24"
            />
          </div>
        </div>

        <button
          type="submit"
          className="w-full bg-blue-600 text-white py-2 px-4 rounded-md hover:bg-blue-700 transition duration-300 flex items-center justify-center"
        >
          <PlusCircle className="mr-2" size={20} />
          {formData.id ? 'Update Package' : 'Add Package'}
        </button>
      </form>

      {/* Existing Packages List */}
      <div className="mt-8">
        <h3 className="text-xl font-semibold mb-4 text-gray-800">Existing Travel Packages</h3>
        <div className="space-y-4">
          {travelPackages.map(pkg => (
            <div key={pkg.id} className="border p-4 rounded-lg shadow-sm">
              <div className="flex justify-between items-center">
                <span className="text-lg font-medium">{pkg.title}</span>
                <div className="space-x-2">
                  <button
                    onClick={() => handleEdit(pkg)}
                    className="bg-yellow-500 text-white rounded-md p-2 hover:bg-yellow-600 transition duration-300"
                  >
                    <Edit2 size={18} />
                  </button>
                  <button
                    onClick={() => handleDelete(pkg.id)}
                    className="bg-red-500 text-white rounded-md p-2 hover:bg-red-600 transition duration-300"
                  >
                    <Trash2 size={18} />
                  </button>
                </div>
              </div>
              {/* Display inclusions and exclusions in the package summary */}
              <div className="mt-4">
                <h4 className="font-semibold">Inclusions:</h4>
                <ul className="list-disc pl-5">
                  {pkg.inclusions && pkg.inclusions.map((inc, idx) => (
                    <li key={idx}>{inc}</li>
                  ))}
                </ul>
              </div>
              <div className="mt-2">
                <h4 className="font-semibold">Exclusions:</h4>
                <ul className="list-disc pl-5">
                  {pkg.exclusions && pkg.exclusions.map((exc, idx) => (
                    <li key={idx}>{exc}</li>
                  ))}
                </ul>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default TravelPackageForm;
