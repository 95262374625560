import React from 'react';
import logo from "../../Assets/logo.svg";
import upperLogo from "../../Assets/upperlogo.svg";
import insta from "../../Assets/instagram.svg";
import { Link } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { navSelected } from '../../Store/Atoms/NavAtom';

function Footer() {
  // Use Recoil to read and update the selected state
  const [selected, setSelected] = useRecoilState(navSelected);

  const navItems = [
    { name: 'Hotels', link: '/hotels' },
    { name: 'Villas', link: '/villas' },
    { name: 'Travel Packages', link: '/travel-packages' },
    { name: 'Transportation', link: '/transportation' },
    { name: 'Photography & Videography', link: '/photoShoots' },
    { name: 'Event Management', link: '/eventManagement' },
    { name: 'Adventure', link: '/adventure' },
    { name: 'Wellness and Relaxation', link: '/wellness' },
  ];

  const footerLinks = [
    { name: 'ContactUs', link: '/contact' },
    { name: 'Terms & Conditions', link: '/terms-conditions' },
    { name: 'Privacy Policy', link: '/privacy-policy' },
    { name: "About", link: "/aboutUs"},
    { name: "Faq" , link: "/faq"},
  ];


  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  return (
    <div className='w-full bg-[#222222] text-white'>
      <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 w-11/12 mx-auto px-6 py-10 gap-8'>
        <div className='flex justify-center items-center'>
          <Link to="/" className="flex items-center" onClick={() => { setSelected(''); window.scrollTo(0, 0); }}>
            <img src={logo} className="h-12 w-40 object-cover" alt="Logo" />
          </Link>
        </div>

        <div className='flex flex-col gap-y-5'>
          <h3 className='text-orange-500 text-xl'>Our Services</h3>
          <ul className='space-y-2 '>
            {navItems.map((item) => (
              <li key={item.name} className="relative flex flex-col transition duration-300 cursor-pointer items-center group">
                <Link
                  to={item.link}
                  className={`flex w-full py-2 pl-3 pr-4 ${selected === item.name.toLowerCase() ? 'text-[#F26222]' : 'text-white'} md:p-0`}
                  onClick={() => { setSelected(item.name.toLowerCase()); window.scrollTo(0, 0); }}
                >
                  <span className="text-base">{item.name}</span>
                </Link>
              </li>
            ))}
          </ul>
        </div>
        
        {/* help section */}
        <div className='flex flex-col space-y-4'>
          <div className='flex flex-col gap-y-5'>
            <h3 className='text-orange-500 text-xl'>Help</h3>
            <ul className='space-y-2'>
              {footerLinks.map((item) => (
                <li key={item.name} className="relative flex flex-col transition duration-300 cursor-pointer items-center group">
                  <Link
                    to={item.link}
                    className={`flex w-full py-2 pl-3 pr-4 ${selected === item.name.toLowerCase() ? 'text-[#F26222]' : 'text-white'} md:p-0`}
                    onClick={() => { setSelected(item.name.toLowerCase()); window.scrollTo(0, 0); }}
                  >
                    <span className="text-base">{item.name}</span>
                  </Link>
                </li>
              ))}
            </ul>
          </div>

          <div className='flex flex-col gap-y-5'>
            <h3 className='text-orange-500 text-xl'>Become a Partner</h3>
            <ul className='space-y-2'>
              <li className="relative flex flex-col transition duration-300 cursor-pointer items-center group">
                <Link
                  to={"/partnersContact"}
                  className={`flex w-full py-2 pl-3 pr-4 md:p-0`}
                  onClick={() => { window.scrollTo(0, 0); }}
                >
                  Partners
                </Link>
              </li>
            </ul>
          </div>
        </div>

        <div className='flex flex-col gap-y-5'>
          <h3 className='text-orange-500 text-xl'>Social Media</h3>
          <div className='flex gap-x-4'>
            <a
              href="https://www.instagram.com/travoolah/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={insta} alt="Instagram" className="w-8 h-8" />
            </a>
          </div>
        </div>

        <div className='flex justify-center items-center'>
          <button onClick={scrollToTop}>
            <img src={upperLogo} alt='upperLogo' className='w-10' />
          </button>
        </div>
      </div>
      <div className='text-center bg-[#383939] py-5'>
        <p>Made with ❤️ by <a href="https://www.webinvolve.com" target='_blank'>Web Involve</a></p>
      </div>
    </div>
  );
}

export default Footer;
